import { Component, OnInit, AfterViewInit, OnChanges, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { ComponentBase } from '@app/common/component-base';
import { Unit } from '@app/data/models/project/unit.model';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { CommonAjaxService } from '@app/data/ajax/common-ajax.service';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { Block } from '@app/data/models/project/block.model';
import { Phase } from '@app/data/models/project/phase.model';
import { UnitType } from '@app/data/models/project/unit-type.model';
import { UnitStatus } from '@app/data/models/project/unit-status.model';
import { PubSubService } from '@app/core/services/pub-sub.service';
export class MapBoarMarker {
  width: number;
  height: number;
  x_pos: number;
  y_pos: number;

  constructor() {
    this.width = 25;
    this.height = 25;
  }
}

@Component({
  selector: 'app-map-board-new',
  templateUrl: './map-board-new.component.html',
  styleUrls: ['./map-board-new.component.scss']
})
export class MapBoardNewComponent extends ComponentBase implements AfterViewInit {

  mapImage: HTMLImageElement = new Image();
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  markers: MapBoarMarker[] = [];
  @Input() MAP_STYLES = {
    'min-height': '500px'
  };
  @Input()
  pins: any[] = [];
  @Input()
  phaseIndex = -1;
  @Input()
  imageSrc: string;
  @Input()
  uniqueId = -1;
  @Input()
  enableRemoveButton = false;
  @Output()
  imageWasRemoved: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  phase: Phase;
  @Input()
  blocks: Block[] = [];
  @Input()
  unitTypes: UnitType[] = [];

  @Input()
  isReadOnly = false;


  @Input()
  galleryImages: { src: string, typeId: number }[] = [];

  @Output()
  mapClick: EventEmitter<{ source: any, phaseIndex: number, unitIndex: number, type: string }>
    = new EventEmitter<{ source: any, phaseIndex: number, unitIndex: number, type: string }>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onAfterReserve: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  viewingType = 'create';

  isAddFormDisabled = false;

  pinSource: any[] = [];

  pinImageSrc: string;

  IS_FIRST_LOAD = true;
  /**
   * Itemsource for viewing
   */
  @Input()
  mapUnitSource: Unit[] = [];

  CANVAS_DIMESIONS_STYLES = {
    height: '0px',
    width: '0px'
  };

  editState: any = null;
  removedUnitIdsEdit: any = [];
  listener: Subscription;
  moveListener: Subscription;

  isOnMoveState = false;
  constructor(
    private local: LocalStorageService,
    private utilities: UtilitiesService,
    private commonAjaxService: CommonAjaxService,
    private sanitizer: DomSanitizer,
    private changeRef: ChangeDetectorRef,
    private pubsub: PubSubService
  ) {
    super();
    this.listener = this.pubsub.On('PinUpdate')
      .subscribe((res) => {
        this.IS_BUSY = res;
      });
    this.moveListener = this.pubsub.On('OnMapPinNewMove')
      .subscribe((res) => {

      });
  }


  ngOnInit() {
    this.mapImage.src = this.imageSrc;
    this.pinSource = this.pins;
    if (this.viewingType === 'edit') {
      for (const x of this.pins) {
        if (x.y_pos === null || x.x_pos === null) {
          this.removedUnitIdsEdit.push(x);
        }
      }
    }
  }

  removeImage() {
    this.imageSrc = null;
    this.imageWasRemoved.emit(true);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.draw();
    }, 200);
  }

  async processChange() {
    if (this.viewingType === 'reservation' || this.viewingType === 'view') {
      const workers: any[] = [];
      for (const item of this.mapUnitSource) {
        if (item.mapPin) {
          const itemResult = new Promise((resolve) => {
            resolve(item);
          });
          const blockResult = item.getBlock().toPromise();
          const unitTypeResult = item.getType().toPromise();
          workers.push(forkJoin([itemResult, blockResult, unitTypeResult]));
        }
      }
      return forkJoin(workers).toPromise();
    } else {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
  }

  onMouseDown(event: any) {
    event.preventDefault();
    if (this.isReadOnly || this.isOnMoveState) {
      return;
    }
    this.pins = this.pins.filter(x => x.id !== -1);
    setTimeout(() => {
      // Move the marker when placed to a better location
      const marker = new MapBoarMarker();
      marker.x_pos = event.offsetX - (marker.width / 4);
      marker.y_pos = event.offsetY - (marker.height / 3);
      const results: Unit = new Unit({
        id: -1,
        mapPin: {
          x: marker.x_pos,
          y: marker.y_pos
        },
        lotNumber: null,
        status: UnitStatus.AVAILABLE,
      });
      this.pins.push(results);
    }, 100);

  }

  /**
   * This one is to make the image generate its consitent resolution
   */
  async draw() {
    if (this.utilities.validURL(this.imageSrc) && this.imageSrc !== null) {
      this.IS_BUSY = true;
      this.commonAjaxService.request('get', this.imageSrc, { responseType: 'blob' })
        .subscribe((res: any) => {
          const reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onload = (e: any) => {
            const imageData = new Image();
            imageData.src = e.target.result;
            this.pinImageSrc = (this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result) as any);
            setTimeout(() => {
              this.CANVAS_DIMESIONS_STYLES.height = `${imageData.height}px`;
              this.CANVAS_DIMESIONS_STYLES.width = `${imageData.width}px`;
              if (this.viewingType === 'reservation' || this.viewingType === 'view') {
                setTimeout(() => {
                  this.processChange().then((source) => {
                    this.processPins(source);
                    this.IS_FIRST_LOAD = false;
                    this.IS_BUSY = false;
                  });
                }, 200);
              } else {
                this.IS_BUSY = false;
              }
            }, 50);
          };
        }, (err) => {
          this.IS_BUSY = false;
        });
    } else {
      if (this.imageSrc !== null) {
        this.pinImageSrc = this.imageSrc;
        const request = new XMLHttpRequest();
        request.open('GET', this.imageSrc, true);
        request.responseType = 'blob';
        request.onload = () => {
          const reader = new FileReader();
          reader.readAsDataURL(request.response);
          reader.onload = (e: any) => {
            const imageData = new Image();
            imageData.src = e.target.result;
            setTimeout(() => {
              this.CANVAS_DIMESIONS_STYLES.height = `${imageData.height}px`;
              this.CANVAS_DIMESIONS_STYLES.width = `${imageData.width}px`;
            }, 50);
          };
        };
        request.send();
      }
    }
  }

  onMapSubmit(data: any, index: number) {
    this.pins[index] = data;
    this.mapClick.emit(data);
  }

  onUnMapData(data: any, index: any) {
    if (data.id === -1) {
      this.pins.splice(index, 1);
    }
  }

  processPins(source: any) {
    this.pins = [];
    for (const item of source) {
      this.pins.push({
        id: item[0].id,
        status: item[0].status,
        x_pos: item[0].mapPin.x,
        y_pos: item[0].mapPin.y,
        lotNumber: item[0].lotNumber,
        uniqueId: item[0].typeId,
        blockId: item[1].id,
        pinTitle: `${item[2].name}`,
        others: {
          block: item[1],
          unitType: item[2],
          unit: item[0]
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.listener.unsubscribe();
  }

}
