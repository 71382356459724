import { BaseModel } from './base.model';

export class MediaFile extends BaseModel {

  /**
   * File name of the file.
   * Note: It is better to always set this during file upload so that when the file is downloaded it will be
   * correctly associated to programs by the OS.
   */
  fileName: string;

  /**
   * The MIME type of the file.
   * The server will assign octet-stream if this is not specified.
   * Note: For images, it is better to set this so that the image can display properly on the web browsers.
   */
  mimeType: string;

  /**
   * Size of the bytes.
   * When uploading the bytes and this is not specified, the server
   * will just use the actual length of the byte array
   */
  size: number;

  /**
   * This is the base-64 representation of the bytes.
   * Note: This is a write-only field so that when listing all the media files
   * in the API, it will not consume a lot of bandwidth. To get the actual bytes
   * use the "preview" link of this resource.
   */
  content: string;
}
