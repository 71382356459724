import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AppConstants } from '@app/common/constants';

export abstract class CommonAjaxBase {
    protected API_TOKEN_USERNAME = AppConstants.API_TOKEN_USERNAME;
    protected API_TOKEN_PASS = AppConstants.API_TOKEN_PASS;

    constructor() {

    }

    handleError(error: HttpErrorResponse) {
        let errorResponse = null;
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error);
            errorResponse = null;
        } else if (error.error instanceof ProgressEvent) {
            errorResponse = error;
            console.error(error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: `, error.error);
            errorResponse = error;
        }
        // return an throwError with a user-facing error message

        return throwError(errorResponse);
    }

    fetchAsnyc(promise: Promise<any>) {
        return promise
            .then(data => ({ data, error: null }))
            .catch(error => ({ error, data: null }));
    }
}
