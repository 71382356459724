import { Injectable } from '@angular/core';
import { decompress, compress } from 'lz-string';
@Injectable({
  providedIn: 'root'
})
export class SessionStorageService implements Storage {
  constructor() { }
  [key: string]: any;
  length: number = sessionStorage.length;

  getItem(key: string): any {
    const item = decompress(sessionStorage.getItem(key));
    return JSON.parse(item);
  }

  setItem(key: string, value: string): void {
    const item = compress(JSON.stringify(value));
    sessionStorage.setItem(key, item);
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  key(index: number): string {
    return sessionStorage.key(index);
  }

  clear(): void {
    sessionStorage.clear();
  }

}
