import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  TemplateRef,
  ElementRef,
  HostListener,
  Renderer2
} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Lightbox } from '@ngx-gallery/lightbox';
import { ImageSize, ThumbnailsPosition, Gallery, ImageItem } from '@ngx-gallery/core';
import { ComponentBase } from '@app/common/component-base';
import { Block } from '@app/data/models/project/block.model';
import { UnitType } from '@app/data/models/project/unit-type.model';
import { Phase } from '@app/data/models/project/phase.model';
import { Unit } from '@app/data/models/project/unit.model';
import { UnitService } from '@app/data/services/project/unit.service';
import { UnitStatus } from '@app/data/models/project/unit-status.model';
import { PubSubService } from '@app/core/services/pub-sub.service';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { MapPin } from '@app/data/models/project/map-pin.model';

@Component({
  selector: 'app-map-pin-new',
  templateUrl: './map-pin-new.component.html',
  styleUrls: ['./map-pin-new.component.scss']
})
export class MapPinNewComponent extends ComponentBase implements AfterViewInit {

  @Input()
  source: Unit = new Unit();

  @Input()
  blocks: Block[] = [];

  @Input()
  unitTypes: UnitType[] = [];

  @Input()
  phase: Phase = null;

  @Input()
  title: string;

  @Input()
  pinViewingType = 'create';

  @Input()
  disableAddForm = false;

  @Input()
  galleryImages: { src: string, typeId: number }[] = [];

  @Output()
  clickPin: EventEmitter<Unit> = new EventEmitter<Unit>();

  @Output()
  isUnMap: EventEmitter<Unit> = new EventEmitter<Unit>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onAfterReserve: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('pop')
  pop: any;

  @ViewChild('lotNoInput') lotNoInput: ElementRef;

  @ViewChild('mapPinTemplate') mapPinTemplate: ElementRef;

  lotNumber: string;
  STATUS_CLASS = 'available';

  lotForm: FormGroup;

  pinTitle = '';
  trackingNo: string;

  unitGalleryPictures: ImageItem[] = [];

  PIN_STYLES = {
    'top': '0px',
    'left': '0px'
  };
  modalRef: BsModalRef;
  filteredBlocks: Block[] = [];

  draggable = false;

  moveSource: MapPin = {
    x: 0,
    y: 0
  };

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private lightbox: Lightbox,
    private gallery: Gallery,
    private UNIT_SERVICE: UnitService,
    private pubsub: PubSubService,
    private renderer: Renderer2) {
    super();
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      // Do things
      this.pop.hide();
    }
  }


  onHidden() {
    if ((this.pinViewingType === 'create' || this.pinViewingType === 'edit') && this.lotForm.invalid) {
      this.isUnMap.emit(this.source);
    }
  }

  async submitForm(data: any) {
    this.source.lotNumber = data.name;
    this.pop.hide();
    const val = this.lotForm.value;
    const block = this.blocks.find(x => x.id === +val.block);
    const unitType = this.unitTypes.find(x => x.id === +val.unitType);
    this.pubsub.Broadcast('PinUpdate', true);
    if (this.lotForm.valid && this.source.id === -1) {
      const unit: any = await this.UNIT_SERVICE.create(new Unit({
        lotNumber: +val.lotNumber,
        area: val.lotArea,
        totalContractPrice: val.totalContractPrice,
        type: unitType._links['self'].href,
        mapPin: this.source.mapPin,
        status: UnitStatus.AVAILABLE,
        block: block._links['self'].href,
        floorArea: val.floorArea
      })).toPromise();
      this.source = unit;
      this.clickPin.emit(unit);
    } else {
      const unit: any = await this.UNIT_SERVICE.patch(new Unit({
        id: this.source.id,
        lotNumber: +val.lotNumber,
        area: val.lotArea,
        totalContractPrice: val.totalContractPrice,
        type: unitType._links['self'].href,
        mapPin: this.source.mapPin,
        status: this.source.status,
        block: block._links['self'].href,
        floorArea: val.floorArea,
        _links: this.source._links
      })).toPromise();
      this.source = unit;
      this.clickPin.emit(unit);
    }
    this.pubsub.Broadcast('PinUpdate', false);
  }

  async unMap() {
    this.pubsub.Broadcast('PinUpdate', true);
    if (this.source.id !== -1) {
      this.source.id = -1;
    }
    this.pop.hide();
    this.isUnMap.emit(this.source);
    this.pubsub.Broadcast('PinUpdate', false);
  }

  ngOnInit() {
    this.filteredBlocks = this.blocks.filter(x => x.phaseId === this.phase.id);
    this.PIN_STYLES['top'] = `${this.source.mapPin.y}px`;
    this.PIN_STYLES['left'] = `${this.source.mapPin.x}px`;
    this.STATUS_CLASS = this.source.status;
    this.pinTitle = `Unit For: (${this.phase.name})`;
    this.lotForm = this.formBuilder.group({
      'lotNumber': new FormControl(this.source.lotNumber, [Validators.required, Validators.minLength(1)]),
      'block': ['', Validators.required],
      'unitType': ['', Validators.required],
      'lotArea': ['', Validators.required],
      'floorArea': ['', Validators.required],
      'totalContractPrice': [0, Validators.required]
    });
    if (this.source.id !== -1) {
      this.lotForm = this.formBuilder.group({
        'lotNumber': new FormControl(this.source.lotNumber, [Validators.required, Validators.minLength(1)]),
        'block': [this.source.blockId, Validators.required],
        'unitType': [this.source.typeId, Validators.required],
        'lotArea': [this.source.area, Validators.required],
        'floorArea': [this.source.floorArea, Validators.required],
        'totalContractPrice': [this.source.totalContractPrice, Validators.required]
      });
    } else {
      setTimeout(() => {
        if (typeof this.lotForm !== 'undefined' && this.lotForm.invalid) {
          this.pop.show();
        }
      }, 100);
    }
  }

  openReservationModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {});
    this.pop.hide();
  }

  openImageModal() {
    if (this.unitGalleryPictures === null || this.unitGalleryPictures.length <= 0) {
      return;
    }
    // Get a lightbox gallery ref
    const lightboxRef = this.gallery.ref('lightbox');

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Left
    });
    // Load items into the lightbox gallery ref
    lightboxRef.load(this.unitGalleryPictures);
    this.lightbox.open(0);
  }


  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + '-' + s4();
  }

  submitReserveForm() {
    this.pop.hide();
  }

  setMoveMode() {
    this.draggable = true;
    this.STATUS_CLASS = 'MOVE';
    this.pinTitle = `Confirm Move Pin [Unit For: (${this.phase.name})]`;
    this.renderer.addClass(this.mapPinTemplate.nativeElement, 'map-pin-drag');
    this.moveSource = this.source.mapPin;
    this.pop.hide();
  }

  onDrop(event: CdkDrag) {
    if (this.draggable) {
      const element = event.getRootElement();
      const boundingClientRect = element.style.transform.replace('translate3d(', '').replace(')', '');
      const finalCoordinates = boundingClientRect.split(',');
      this.moveSource = {
        x: this.source.mapPin.x + +(finalCoordinates[0].replace('px', '')),
        y: this.source.mapPin.y + +(finalCoordinates[1].replace('px', ''))
      };
    }
  }

  async confirmMove() {
    this.source.mapPin = this.moveSource;
    await this.submitForm(this.lotForm.value);
    this.ngOnInit();
    this.clearMovePin();
  }

  cancelMove() {
    this.clearMovePin();
    this.ngOnInit();
  }

  clearMovePin() {
    this.draggable = false;
    this.renderer.removeClass(this.mapPinTemplate.nativeElement, 'map-pin-drag');
    this.renderer.removeClass(this.mapPinTemplate.nativeElement, 'cdk-drag');
    this.mapPinTemplate.nativeElement.style.transform = null;
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {

  }
}
