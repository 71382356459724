import { Component, OnInit, ViewEncapsulation, ContentChildren, AfterContentInit, QueryList } from '@angular/core';
import { ComponentBase } from 'app/common/component-base';
import { AdminSidebarWrapperDirective } from '@app/shared/directives/admin-sidebar-wrapper.directive';
import { AdminSidebarLinkDirective } from '@app/shared/directives/admin-sidebar-link.directive';
import { AdminSidebarHeaderWrapperDirective } from '@app/shared/directives/admin-sidebar-header-wrapper.directive';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminSidebarComponent extends ComponentBase implements AfterContentInit {

  @ContentChildren(AdminSidebarWrapperDirective)
  contentWidget: QueryList<AdminSidebarWrapperDirective>;
  @ContentChildren(AdminSidebarHeaderWrapperDirective)
  headerWidget: QueryList<AdminSidebarHeaderWrapperDirective>;
  @ContentChildren(AdminSidebarLinkDirective)
  links: QueryList<AdminSidebarLinkDirective>;
  constructor() {
    super();
  }

  ngOnInit() {

  }

  ngAfterContentInit() {

  }

  ngOnDestroy(): void {

  }
}
