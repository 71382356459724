import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
  HostBinding,
  AfterViewInit,
  OnInit
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Directive({
  selector: '[appFileDrop]'
})
export class FileDropDirective implements AfterViewInit, OnInit {

  @Input()
  allowedExtensions: Array<string> = ['png', 'jpg', 'bmp', 'jpeg'];
  @Input()
  withBorder = true;
  @Output()
  filesChangeTrigger: EventEmitter<File[]> = new EventEmitter();
  @Output()
  filesInvalidTrigger: EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.background')
  private background = 'rgb(249, 249, 249)';
  @HostBinding('style.border')
  private border: SafeStyle;
  constructor(private sanitizer: DomSanitizer) {

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.withBorder === false) {
        this.border = '';
      } else {
        this.border = this.sanitizer.bypassSecurityTrustStyle('1.2px dashed rgb(185, 185, 185)');
      }
    }, 20);
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#999';
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eee';
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eee';
    const files = evt.dataTransfer.files;
    const valid_files: Array<File> = [];
    const invalid_files: Array<File> = [];
    if (files.length > 0) {
      for (const file of files) {
        const ext = file.name.split('.')[file.name.split('.').length - 1];
        if (this.allowedExtensions.lastIndexOf(ext) !== -1) {
          valid_files.push(file);
        } else {
          invalid_files.push(file);
        }
      }
      if (valid_files.length >= 1) {
        this.filesChangeTrigger.emit(valid_files);
      }
      if (invalid_files.length >= 1) {
        this.filesInvalidTrigger.emit(invalid_files);
      }

    }
  }
}
