import { BaseModel } from '../base.model';
import { Unit } from './unit.model';
import { Phase } from './phase.model';

export class Block extends BaseModel {
    name: string;
    phase: Phase;
    phaseId?: any;

    getUnits() {
        return this.getRelationArray(Unit, 'units');
    }
}
