import { BaseModel } from '../base.model';
import { Phase } from './phase.model';
import { Amenity } from './amenity.model';
import { UnitType } from './unit-type.model';
import { MediaFile } from '../media-file.model';
import { ProjectMediaFile } from './project-media-file.model';
import { ProjectStatus } from '../project-status.model';

export class Project extends BaseModel {
    name: string;
    type: string;
    totalCost: number;
    totalLandArea: number;
    location: string;
    mainDescription: string;
    status: ProjectStatus;
    phases: Phase[] = [];
    amenities: Amenity[] = [];
    ['unit-types']: UnitType[] = [];
    images: ProjectMediaFile[] = [];

    getPhases() {
        return this.getRelationArray(Phase, 'phases');
    }

    getAmenities() {
        return this.getRelationArray(Amenity, 'amenities');
    }

    getUnitTypes() {
        return this.getRelationArray(UnitType, 'unit-types');
    }

    getImages() {
        return this.getRelationArray(ProjectMediaFile, 'images');
    }

    addImage(file: ProjectMediaFile) {
        return this.updateRelation('images', file);
    }

    removeImage(file: ProjectMediaFile) {
        return this.deleteRelation('images', file);
    }

}
