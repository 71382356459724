import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieStorageService } from './services/cookie-storage.service';
import { LocalStorageService } from './services/local-storage.service';
import { SessionStorageService } from './services/session-storage.service';
import { UtilitiesService } from './services/utilities.service';
import { ApplicationStorageService } from './services/application-storage.service';
import { AdminSidebarComponent } from './components/admin-sidebar/admin-sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatCardModule,
  MatInputModule,
  MatProgressBarModule,
  MatDialogModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatRadioModule,
  MatTableModule,
  MatPaginatorModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatSortModule,
  MatSelectModule,
  MatIconModule,
  MatMenuModule,
  MatBottomSheetModule,
  MatListModule
} from '@angular/material';
import { BsDropdownModule, BsDatepickerModule, PopoverModule, PaginationModule, ModalModule } from 'ngx-bootstrap';
import { AdminTopbarComponent } from './components/admin-topbar/admin-topbar.component';
import { AdminSidebarWrapperDirective } from './directives/admin-sidebar-wrapper.directive';
import { AdminSidebarLinkDirective } from './directives/admin-sidebar-link.directive';
import { RouterModule } from '@angular/router';
import { MapBoardComponent } from './components/map-board/map-board.component';
import { MapPinComponent } from './components/map-pin/map-pin.component';
import { SharedAutocompleteComponent } from './components/shared-autocomplete/shared-autocomplete.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { AdminSidebarHeaderWrapperDirective } from './directives/admin-sidebar-header-wrapper.directive';
import { GalleryCarouselComponent } from './components/gallery-carousel/gallery-carousel.component';
import { MessageBoxBsComponent } from './components/message-box-bs/message-box-bs.component';
import { FileDropDirective } from './directives/file-drop.directive';
import { SlickComponent } from './components/slick/slick.component';
import { SlickItemDirective } from './directives/slick-item.directive';
import { ValueChangePipe } from './pipes/value-change.pipe';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { GallerizeModule } from '@ngx-gallery/gallerize';
import { BusyIndicatorComponent } from './components/busy-indicator/busy-indicator.component';
import { SwTableComponent } from './components/sw-table/sw-table.component';
import { PermissionDirective } from './directives/permission.directive';
import { SwTableControlComponent } from './components/sw-table-control/sw-table-control.component';
import { SwTableSourceDirective } from './directives/sw-table-source.directive';
import { FileDrawerViewerComponent } from './components/file-drawer-viewer/file-drawer-viewer.component';
import { RecentTransactionTypePipe } from './pipes/recent-transaction-type.pipe';
import { MapBoardNewComponent } from './components/map-board-new/map-board-new.component';
import { MapPinNewComponent } from './components/map-pin-new/map-pin-new.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    DragDropModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatProgressBarModule,
    MatDialogModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatSortModule,
    MatSelectModule,
    MatIconModule,
    GalleryModule,
    LightboxModule,
    GallerizeModule,
    MatMenuModule,
    MatBottomSheetModule,
    MatListModule
  ],
  exports: [
    AdminSidebarComponent,
    AdminTopbarComponent,
    AdminSidebarWrapperDirective,
    AdminSidebarLinkDirective,
    MapBoardComponent,
    SwTableComponent,
    SharedAutocompleteComponent,
    AdminSidebarHeaderWrapperDirective,
    GalleryCarouselComponent,
    FileDropDirective,
    SlickComponent,
    SlickItemDirective,
    ValueChangePipe,
    BusyIndicatorComponent,
    PermissionDirective,
    SwTableControlComponent,
    SwTableSourceDirective,
    RecentTransactionTypePipe,
    MapBoardNewComponent
  ],
  declarations: [
    AdminSidebarComponent,
    AdminTopbarComponent,
    AdminSidebarWrapperDirective,
    AdminSidebarLinkDirective,
    MapBoardComponent,
    MapPinComponent,
    SwTableComponent,
    SharedAutocompleteComponent,
    MessageBoxComponent,
    AdminSidebarHeaderWrapperDirective,
    GalleryCarouselComponent,
    MessageBoxBsComponent,
    FileDropDirective,
    SlickComponent,
    SlickItemDirective,
    ValueChangePipe,
    BusyIndicatorComponent,
    PermissionDirective,
    SwTableControlComponent,
    SwTableSourceDirective,
    FileDrawerViewerComponent,
    RecentTransactionTypePipe,
    MapBoardNewComponent,
    MapPinNewComponent
  ],
  providers: [
    CookieStorageService,
    LocalStorageService,
    SessionStorageService,
    UtilitiesService,
    ApplicationStorageService
  ],
  entryComponents: [
    MessageBoxComponent,
    MessageBoxBsComponent,
    FileDrawerViewerComponent
  ]
})
export class SharedModule { }
