import { Injectable } from '@angular/core';
import { ApplicationStorageService, ApplicationStorageKey } from '@app/shared/services/application-storage.service';
import { SessionLoggerService, CurrentUser } from '@app/core/services/session-logger.service';
import { AuthService } from '../infrastructure/data/auth/auth.service';

@Injectable()
export class SessionInitializerService {

  constructor(
    private applicationStorage: ApplicationStorageService,
    private sessionLogger: SessionLoggerService,
    private AUTH_SERVICE: AuthService
  ) { }

  initialize(): Promise<any> {
    return new Promise((resolve, reject) => {
      const token = this.applicationStorage.GetItem(ApplicationStorageKey.token);
      const userInfo = this.applicationStorage.GetItem(ApplicationStorageKey.userInfo);
      if (token && userInfo) {
        const tokenData = JSON.parse(token);
        const userInfoData = JSON.parse(userInfo) as CurrentUser;
        if (tokenData.hasOwnProperty('access_token')) {
          this.sessionLogger.access_token = tokenData.access_token;
          this.AUTH_SERVICE.getUserInfo(userInfoData.loginInfo.username)
            .subscribe((res: any) => {
              this.applicationStorage.SetItem(ApplicationStorageKey.userInfo,
                JSON.stringify(res)
              );
              this.sessionLogger.currentUserInfo = res;
              resolve();
            }, (err) => {
              this.sessionLogger.logout();
              resolve();
            });
        }
      } else {
        resolve();
      }
    });
  }
}
