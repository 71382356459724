import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SessionLoggerService, CurrentUser } from '@app/core/services/session-logger.service';

import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild('navbar')
  navbar: ElementRef;
  activeLink = '';
  routeEventListener: Subscription;
  isLoggedIn = false;
  currentUser: CurrentUser;
  constructor(
    private router: Router,
    private sessionLogger: SessionLoggerService
  ) {
    this.routeEventListener = router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          this.activeLink = event.url;
        }
      });

  }

  ngOnInit() {
    this.isLoggedIn = this.sessionLogger.isLoggedIn();
    this.currentUser = this.sessionLogger.currentUserInfo;
  }

  navigate() {
    if (this.currentUser.loginInfo.roleName.toLowerCase() === 'investor') {
      this.router.navigate(['/investor']);
    } else if (this.currentUser.loginInfo.roleName.toLowerCase() === 'client') {
      this.router.navigate(['/client']);
    } else {
      this.router.navigate([this.currentUser.loginInfo.landingPage]);
    }
  }

  toggle() {
    if (this.navbar.nativeElement.classList.contains('nav-hide')) {
      this.navbar.nativeElement.classList.remove('nav-hide');
    } else {
      this.navbar.nativeElement.classList.add('nav-hide');
    }
  }

}
