import { BaseModel } from '@app/data/models/base.model';
import { Unit } from '@app/data/models/project/unit.model';


export class TempReservation extends BaseModel {
    refNumber: string;
    name: string;
    email: string;
    contactNumber: string;
    reserveDate: Date;
    unit: Unit;
}
