import { BaseModel } from '../base.model';
import { MediaFile } from '../media-file.model';
import { Block } from './block.model';
import { Project } from './project.model';
import { Amenity } from './amenity.model';

export class Phase extends BaseModel {
    name: string;
    project: Project;
    map: MediaFile;

    getBlocks() {
        return this.getRelationArray(Block, 'blocks');
    }

    getMap() {
        return this.getRelation(MediaFile, 'map');
    }

    getAmenities() {
        return this.getRelationArray(Amenity, 'amenities');
    }
}
