import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ApplicationStorageService, ApplicationStorageKey } from '@app/shared/services/application-storage.service';
import { SessionLoggerService } from '@app/core/services/session-logger.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
@Injectable()
export class DataHttpInterceptor implements HttpInterceptor {

  constructor(
    private applicationStorage: ApplicationStorageService,
    private sessionLogger: SessionLoggerService,
    private utilities: UtilitiesService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.applicationStorage.GetItem(ApplicationStorageKey.token);
    if (token && this.utilities.validURL(req.url)) {
      const tokenData = JSON.parse(token);
      if (tokenData.hasOwnProperty('access_token')) {
        req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${tokenData.access_token}`) });
      }
    }
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          console.log('err: ', err);
          if (err.status === 401 || err.status === 403) {
            this.sessionLogger.logout();
            location.reload();
          }
        }
      })
    );
  }
}
