import { BaseModel } from '@app/data/models/base.model';
import { MediaFile } from '@app/data/models/media-file.model';

export class User extends BaseModel {

    fullName: string;
    firstName: string;
    lastName: string;
    middleName: string;
    suffix: string;
    address: string;
    email: string;
    contactNo: string;
    occupation: string;
    maritalStatus: string;
    profilePhoto: MediaFile;
    loginInfo: any;
    birthDate: Date;

    getProfilePhoto() {
        return this.getRelation(MediaFile, 'profilePhoto');
    }
}
