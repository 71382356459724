import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { MediaFile } from '../models/media-file.model';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class MediaFileService extends BaseService<MediaFile> {
  constructor(injector: Injector) {
    super(MediaFile, 'media-files', injector);
  }

}
