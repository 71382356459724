import {
  Component, OnInit, Input, OnChanges, SimpleChanges, HostListener
} from '@angular/core';
import { MediaFile } from '@app/data/models/media-file.model';
import { GalleryItem, ImageItem, Gallery } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';
declare var $: any;
@Component({
  selector: 'app-gallery-carousel',
  templateUrl: './gallery-carousel.component.html',
  styleUrls: ['./gallery-carousel.component.scss']
})
export class GalleryCarouselComponent implements OnInit, OnChanges {

  items: GalleryItem[] = [];
  @Input()
  imageSource: string[] = [];

  @Input()
  disableThumb = false;

  @Input()
  imageSize: 'cover' | 'contain' = 'cover';

  @Input()
  counter = true;

  sliderInterval = 10000;

  galleryId = `gallery-${Math.floor(Math.random() * 30)}`;

  galleryMobileHeight = {
    height: '784px'
  };

  selectedImageFile: MediaFile = null;
  constructor(
    public gallery: Gallery,
    public lightbox: Lightbox) {
    this.galleryId = `gallery-${Math.floor(Math.random() * 10000000)}`;
    this.checkWidth(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWidth(event.target.innerWidth);
  }

  checkWidth(width: number) {
    if (width <= 980) {
      // this.imageSize = 'contain';
      this.galleryMobileHeight.height = '500px';
      if (width <= 400) {
        this.galleryMobileHeight.height = '200px';
      } else if (width <= 700) {
        this.galleryMobileHeight.height = '300px';
      } else if (width <= 850) {
        this.galleryMobileHeight.height = '400px';
      }
    } else {
      this.galleryMobileHeight.height = '784px';
     // this.imageSize = 'cover';
    }
  }

  ngOnInit() {
    this.process();
  }


  process() {
    this.items = this.imageSource.map(item => new ImageItem({ src: item, thumb: item }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imageSource != null && !changes.imageSource.firstChange && changes.imageSource.currentValue) {
      this.process();
    }
  }

  selectImage(image: MediaFile) {
    this.selectedImageFile = image;
  }

}
