import { Injectable, Injector } from '@angular/core';
import { RestService, HalOptions } from 'angular4-hal';
import { Project } from '../../models/project/project.model';
import { BaseService } from '../base-service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BaseService<Project> {
  constructor(injector: Injector) {
    super(Project, 'projects', injector);
  }

  getActiveProjects(options?: HalOptions) {
    return this.search('public-all-active', options);
  }
}
