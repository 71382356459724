import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageComponentBase } from '../../../common/page-component-base';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomePageComponent extends PageComponentBase {
  isUrlEmpty = false;
  constructor(private router: Router) {
    super();
    this.isUrlEmpty = this.router.url !== '/' ? true : false;
  }

  async ngOnInit() {
    this.router.events.subscribe((evt: any) => {
      if (evt instanceof NavigationEnd) {
        this.isUrlEmpty = this.router.url !== '/' ? true : false;
      }
    });
  }

  ngOnDestroy(): void {


  }
}
