import { Injectable } from '@angular/core';
import { User } from '@app/data/models/user.model';
import { ApplicationStorageService, ApplicationStorageKey } from '@app/shared/services/application-storage.service';

@Injectable()
export class SessionLoggerService {

  constructor(
    private applicationStorage: ApplicationStorageService
  ) { }

  currentUserInfo: CurrentUser | null = null;
  access_token: string;

  getUserInfo() {
    return this.currentUserInfo ? this.currentUserInfo : null;
  }
  isLoggedIn() {
    return typeof this.currentUserInfo !== 'undefined' &&
      this.currentUserInfo !== null;
  }

  logout() {
    this.applicationStorage.RemoveItem(ApplicationStorageKey.token);
    this.applicationStorage.RemoveItem(ApplicationStorageKey.userInfo);
  }
}
export class CurrentUser extends User {
  loginInfo: {
    createdDate: Date,
    id: number,
    roleName: string,
    updatedDate: Date | undefined,
    username: string,
    landingPage: string
  };
}
