import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionLoggerService } from '@app/core/services/session-logger.service';
import { ApplicationStorageService, ApplicationStorageKey } from '@app/shared/services/application-storage.service';

const STORAGE_TOKEN_KEY = 'starkwood.token';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
        private sessionLogger: SessionLoggerService,
        private applicationStorage: ApplicationStorageService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.sessionLogger.isLoggedIn()) {
            const data = route.data;
            const currentUser = this.sessionLogger.getUserInfo();
            if (currentUser == null) {
                this.router.navigate(['/']);
                return false;
            }
            if (data.prohibitedRoles == null && data.allowedRoles == null) {
                this.router.navigate(['/']);
                return false;
            }

            if (data.prohibitedRoles && data.allowedRoles) {
                if (typeof data.prohibitedRoles === 'string' && data.prohibitedRoles === '*') {
                    const hasRole = (data.allowedRoles as string[]).includes(currentUser.loginInfo.roleName.toLowerCase());
                    if (!hasRole) {
                        this.router.navigate(['/']);
                    }
                    return hasRole;
                } else {
                    const isProhibited = (data.prohibitedRoles as string[]).includes(currentUser.loginInfo.roleName.toLowerCase());
                    if (isProhibited) {
                        this.router.navigate(['/']);
                    }
                    return !isProhibited;
                }
            }

            return false;
        }

        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
