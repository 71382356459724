import './app.prototypes';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HomePageComponent } from './main/pages/home-page/home-page.component';
import { LoginPageComponent } from './main/pages/login-page/login-page.component';
import { HeaderComponent } from './main/components/header/header.component';
import { FooterBarComponent } from './main/components/footer-bar/footer-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routes';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { DataModule } from '@app/data/data.module';
import { NgwWowModule } from 'ngx-wow';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatCardModule,
  MatProgressBarModule,
  MatDialogModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatRadioModule,
  MatTooltipModule,
} from '@angular/material';

import { BsDropdownModule, BsDatepickerModule } from 'ngx-bootstrap';
import { AdminPageComponent } from './main/pages/admin-page/admin-page.component';
import { AuthGuard as AuthGuard } from './guards/auth.guard';
import { SessionInitializerService } from './initializer/session-initializer.service';
import { HttpClientModule } from '@angular/common/http';
import { LoginGuard } from './guards/login.guard';
import { AccountPageComponent } from './main/pages/account-page/account-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    LoginPageComponent,
    HeaderComponent,
    FooterBarComponent,
    AdminPageComponent,
    AccountPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DataModule,
    CoreModule.forRoot(),
    NgwWowModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatProgressBarModule,
    MatDialogModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatRadioModule,
    MatTooltipModule,

    RouterModule.forRoot(AppRoutes, { enableTracing: false })
  ],
  providers: [
    AuthGuard,
    LoginGuard,
    DatePipe,
    SessionInitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: SetupApp,
      deps: [SessionInitializerService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-pH'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function SetupApp(setup: SessionInitializerService) {
  return () => setup.initialize();
}
