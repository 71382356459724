import { Injectable } from '@angular/core';
import { MatDialog, MatBottomSheet, MatDialogRef } from '@angular/material';
import { MessageBoxComponent } from '../components/message-box/message-box.component';
import { MessageBoxBsComponent } from '../components/message-box-bs/message-box-bs.component';
import { BsModalService } from 'ngx-bootstrap';
import { Subscriber, Subscription } from 'rxjs';
import { MediaFile } from '@app/data/models/media-file.model';
import { FileDrawerViewerComponent } from '../components/file-drawer-viewer/file-drawer-viewer.component';
@Injectable()
export class UtilitiesService {

  constructor(
    private dialog: MatDialog,
    private modalService: BsModalService,
    private bottomSheet: MatBottomSheet
  ) { }

  ShowMaterialMessageBox(config: MessageBoxConfig, type: MessageBoxType = 'primary', buttons: MessageBoxButtons = 'ok') {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      width: 'auto',
      height: 'auto',
      panelClass: 'app-material-modal',
      data: {
        config: config,
        type: type,
        buttons: buttons
      }
    });

    return dialogRef as MatDialogRef<MessageBoxComponent, 'ok' | 'no' | 'confirm' | 'cancel' | 'yes'>;
  }

  ShowBSMessageBox(config: MessageBoxConfig, type: MessageBoxType = 'primary', buttons: MessageBoxButtons = 'ok') {
    const data: any = config;
    data.type = type;
    data.buttons = buttons;
    const modal = this.modalService.show(MessageBoxBsComponent, {
      class: 'modal-dialog-centered',
      initialState: data
    });
    const listener: Subscription = this.modalService.onHide.subscribe((res: any) => {
      listener.unsubscribe();
      this.modalService.setDismissReason(modal.content);
    });
    return this.modalService;
  }

  ShowFileDrawer(items: MediaFile[], mode: 'readonly' | 'update' = 'update') {
    return this.bottomSheet.open(FileDrawerViewerComponent, {
      data: {
        items: items,
        mode: mode
      }
    });
  }

  validURL(str: string) {
    let result = false;
    try {
      const pattern = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/; // fragment locater
      if (!pattern.test(str)) {
        result = false;
      } else {
        result = true;
      }
    } catch (error) {
      result = false;
    }
    return result;
  }
}
export interface MessageBoxConfig {
  title: string;
  content: string | Array<string>;
}

export type MessageBoxType = 'primary' | 'success' | 'danger' | 'warning' | 'default';
export type MessageBoxButtons = 'ok' | 'yesNo' | 'confirmCancel';

