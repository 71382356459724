import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ComponentBase } from '@app/common/component-base';

@Component({
  selector: 'app-busy-indicator',
  templateUrl: './busy-indicator.component.html',
  styleUrls: ['./busy-indicator.component.scss']
})
export class BusyIndicatorComponent extends ComponentBase {

  @Input()
  position = 'absolute';

  @Input()
  isBusy = true;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {

  }
}
