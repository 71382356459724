import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from '@app/common/component-base';
import { PubSubService } from '@app/core/services/pub-sub.service';
import { SessionLoggerService, CurrentUser } from '@app/core/services/session-logger.service';
import { App } from 'app/app.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-topbar',
  templateUrl: './admin-topbar.component.html',
  styleUrls: ['./admin-topbar.component.scss']
})
export class AdminTopbarComponent extends ComponentBase {

  @Input()
  title: string;
  fullName: string;
  onTitleChangeListener: Subscription;
  constructor(
    private PUBSUB: PubSubService,
    private sessionLoggerService: SessionLoggerService
  ) {
    super();
    this.title = '';
    this.onTitleChangeListener = this.PUBSUB.On<App>(App.OnAdminTopBarTitleChange)
      .subscribe((res) => {
        this.title = res;
      });
    const userInfo = this.sessionLoggerService.getUserInfo();
    this.fullName = userInfo.firstName + ' ' + userInfo.lastName;
  }

  ngOnInit() {
  }

  logout() {
    this.sessionLoggerService.logout();
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.onTitleChangeListener.unsubscribe();
  }

}
