import { Directive, Input, OnInit, ElementRef } from '@angular/core';
import { ApplicationStorageService, ApplicationStorageKey } from '../services/application-storage.service';
import { PermissionService } from '@app/shared/service/permission.service';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnInit {

  constructor(
    private storage: ApplicationStorageService,
    private permissionSvc: PermissionService,
    private el: ElementRef
  ) { }

  @Input('appPermission') key: string;

  ngOnInit(): void {
    const userInfo = this.storage.GetItem(ApplicationStorageKey.userInfo);
    if (!userInfo) {
      return;
    }

    const user = JSON.parse(userInfo);

    if (user.loginInfo.roleName !== 'Client' && this.key === 'clients.view') {
      this.el.nativeElement.style.display = 'none';
    } else {
      this.el.nativeElement.style.display = this.permissionSvc.hasAccess(this.key) ? '' : 'none';
    }
  }

}
