import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Publisher/Subscriber Service
 */
@Injectable()
export class PubSubService {

    private events: Map<string, Subject<any>> = new Map();

    constructor() { }

    /**
     * Subscribes the instance of the assigned event name
     * @param eventName
     * Event name of the delegate
     */
    public On<T>(eventName: T): Observable<any> {
        if (!this.events.has(eventName.toString())) {
            this.events.set(eventName.toString(), new Subject<any>());
        }
        return this.events.get(eventName.toString());
    }

    /**
     * Broadcast data to the specified event channel
     * @param eventName
     * Event name of the delegate
     * @param eventArgs
     * Arguments to pass through the connected channel
     */
    public Broadcast<T>(eventName: T, eventArgs: any) {
        if (!this.events.has(eventName.toString())) {
            return;
        }
        this.events.get(eventName.toString()).next(eventArgs);
    }

    /**
     * Destroy subscriptions by eventName
     * @param eventName
     * event name to destroy
     */
    public destroy(...eventName: string[]) {
        eventName.forEach(e => {
            if (this.events.has(e)) {
                this.events.get(e).unsubscribe();
                this.events.delete(e);
            }
        });

    }
}
