import { OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

export abstract class ComponentBase implements OnInit, OnDestroy {
  IS_BUSY: boolean;
  BUSY_CONTENT: string;
  IS_FIRST_LOAD: boolean;

  constructor() {
    this.IS_BUSY = false;
    this.BUSY_CONTENT = '';
    this.IS_FIRST_LOAD = true;
  }

  abstract ngOnInit(): void;

  getErrorMessage(obj: any) {
    if (obj !== null) {
      if (obj.hasOwnProperty('minlength')) {
        return `Minimum length should be ${obj.minlength.requiredLength}`;
      } else if (obj.hasOwnProperty('maxlength')) {
        return `Max length should be ${obj.maxlength.requiredLength}`;
      } else if (obj.hasOwnProperty('required')) {
        return `This field is required`;
      } else if (obj.hasOwnProperty('email')) {
        return `Invalid email address`;
      } else if (obj.hasOwnProperty('strong')) {
        return `Password is not strong it must contain one digit, one uppercase and one lowercase letter`;
      } else if (obj.hasOwnProperty('passwordNotMatch')) {
        return `Password and Confirm Password does not match`;
      } else if (obj.hasOwnProperty('min')) {
        return `Minimum value must be greater than ${obj.min.min}`;
      } else if (obj.hasOwnProperty('max')) {
        return `Maximum value must not be greater than ${obj.max.max}`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  showFormErrors(form: FormGroup) {
    for (const x in form.controls) {
      if (x) {
        form.get(x).markAsDirty();
        form.get(x).updateValueAndValidity();
        form.get(x).markAsTouched();
        if (form.get(x) instanceof FormGroup) {
          this.showFormErrors(form.controls[x] as FormGroup);
        }
        if (form.get(x) instanceof FormArray) {
          for (const y of (form.controls[x] as FormArray).controls) {
            if (y instanceof FormGroup) {
              this.showFormErrors(y as FormGroup);
            } else {
              y.markAsDirty();
              y.updateValueAndValidity();
            }
          }
        }
      }
    }
  }

  isStandardParams(param: string) {
    return ['sortName', 'sortOrder', 'page', 'size', 'all', 'query'].includes(param);
  }

  abstract ngOnDestroy(): void;
}
