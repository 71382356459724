import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';

export enum ApplicationStorageKey {
  token = 'token',
  userInfo = 'userInfo'
}
export enum StorageType {
  local = 0,
  session = 1,
  cookie = 2
}

@Injectable()
export class ApplicationStorageService {

  constructor(
    private LocalStorage: LocalStorageService,
    private SessionStorage: SessionStorageService) {

  }

  SetItem(key: ApplicationStorageKey, value: any, storageType: StorageType = StorageType.local) {
    if (storageType === StorageType.local) {
      this.LocalStorage.setItem(key.toString(), value);
      return true;
    } else if (storageType === StorageType.session) {
      this.SessionStorage.setItem(key.toString(), value);
      return true;
    } else {
      return false;
    }
  }

  GetItem(key: ApplicationStorageKey, storageType: StorageType = StorageType.local) {
    if (storageType === StorageType.local) {
      return this.LocalStorage.getItem(key.toString());
    } else if (storageType === StorageType.session) {
      return this.SessionStorage.getItem(key.toString());
    } else {
      return null;
    }
  }

  RemoveItem(key: ApplicationStorageKey, storageType: StorageType = StorageType.local) {
    if (storageType === StorageType.local) {
      this.LocalStorage.removeItem(key.toString());
      return true;
    } else if (storageType === StorageType.session) {
      this.SessionStorage.removeItem(key.toString());
      return true;
    } else {
      return false;
    }
  }

  Clear(storageType: StorageType = StorageType.local) {
    if (storageType === StorageType.local) {
      this.LocalStorage.clear();
      return true;
    } else if (storageType === StorageType.session) {
      this.SessionStorage.clear();
      return true;
    } else {
      return false;
    }
  }
}

