import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { Subscription } from 'rxjs';
import { ComponentBase } from '@app/common/component-base';

@Component({
  selector: 'app-shared-autocomplete',
  templateUrl: './shared-autocomplete.component.html',
  styleUrls: ['./shared-autocomplete.component.scss']
})
export class SharedAutocompleteComponent extends ComponentBase implements OnChanges {
  @Input() floatLabel = 'always';
  @Input() placeholder: string;
  @Input() label: string;
  @Input() name: string;
  @Input() displayWith: Function;
  @Input() data: any[] = [];
  @Input() parentFormGroup: FormGroup;
  @Input() canAdd: boolean;
  @Input() loading: boolean;
  @Input() hint: string;
  @Input() readonly: boolean;
  @Input() optionValueName = '';

  @ViewChild('formControlInstance')
  formControlInstance: FormControl;

  @Output() drawerOpened: EventEmitter<any> = new EventEmitter<any>();
  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();
  filteredData: any[];
  hasSelected: boolean;
  selected: any;
  parentListner: Subscription;
  constructor(private cd: ChangeDetectorRef) {
    super();
    this.data = [];
  }

  ngOnInit() {
    this.cd.detectChanges();
    this.parentListner = this.parentFormGroup.get(this.name).valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(50),
      startWith(''),
      map(value => {
        if (typeof value !== 'object') {
          this.selected = null;
        }
        return this._filter(value).slice(0, 20);
      })
    ).subscribe(data => {
      this.filteredData = data;
    });
  }

  ngOnChanges(change: any) {
    if (change['data']) {
      this.filteredData = (this.data || []).slice(0, 20);
    }
  }

  openDrawer() {
    this.drawerOpened.emit(true);
  }

  select(event: any) {
    this.hasSelected = true;
    this.selected = event.option.value;
    this.optionSelected.emit(event);
  }

  focusOut() {
    const val = this.parentFormGroup.get(this.name).value;
    if (this.selected === null && typeof val !== 'object') {
      this.parentFormGroup.get(this.name).setValue('');
      this.optionSelected.emit(null);
    }
  }

  private _filter(value: any): string[] {
    value = value == null ? '' : value;
    let filterValue = '';
    if (typeof value !== 'object') {
      filterValue = (value || '').toLowerCase();
    } else {
      filterValue = (this.displayWith(value) + '').toLowerCase();
    }
    this.data = this.data || [];
    return this.data.filter(option => this._data(option).indexOf(filterValue) !== -1);
  }

  private _data(option: any) {
    let keywords = '';
    if (typeof option !== 'object') {
      keywords = (option || '');
    } else {
      keywords = (this.displayWith(option) || '') + '';
    }
    return keywords.trim().toLowerCase();
  }

  ngOnDestroy() {
    this.parentListner.unsubscribe();
  }

}
