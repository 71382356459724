export const AppConstants = {
    DEFAULT_PAGE_SIZE: 5,
    SOCIAL: {
        FB: 'https://www.facebook.com/starkwoodproperties',
        TWITTER: 'https://twitter.com/starkwoodph',
        INSTAGRAM: 'https://www.instagram.com/starkwoodph/'
    },
    INTAC: {
        SITE: 'http://www.intac.tech'
    },
    API_TOKEN_USERNAME: 'starkwood-client',
    API_TOKEN_PASS: 'starkwood-secret',
    COMPANY: {
        PHONE_NUMBER: '+63 949 350 9999',
        EMAIL: 'info@starkwood.com.ph',
        ADDRESS: '2nd Level, Right Wing, Gaisano Country Mall, Banilad, Cebu City, Philippines'
    }
};
