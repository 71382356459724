import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { TempReservation } from '../models/project/temp-reservation.model';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class TempReservationService extends BaseService<TempReservation> {
  constructor(injector: Injector) {
    super(TempReservation, 'temp-reservations', injector);
  }
}
