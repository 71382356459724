import { OnInit, OnDestroy } from '@angular/core';
import { ComponentBase } from './component-base';

export abstract class PageComponentBase extends ComponentBase {

  constructor() {
    super();
  }

}
