import { Injectable } from '@angular/core';
import { ApplicationStorageKey, ApplicationStorageService } from '@app/shared/services/application-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private storage: ApplicationStorageService
  ) {}

  hasAccess(permissionKey: string) {
    const userInfo = this.storage.GetItem(ApplicationStorageKey.userInfo);
    if (!userInfo) {
      return false;
    }

    const user = JSON.parse(userInfo);
    const keys = permissionKey.split(/\s*,\s*/);

    for (const key of keys) {
      if (!user.loginInfo || !user.loginInfo.roleId || key.match(new RegExp(user.loginInfo.permissionPattern))) {
        return true;
      }
    }

    return false;
  }
}
