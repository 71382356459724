import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { Unit } from '@app/data/models/project/unit.model';
import { ComponentBase } from '@app/common/component-base';
import _ = require('lodash');
import { CommonAjaxService } from '@app/data/ajax/common-ajax.service';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { ProjectMediaFile } from '@app/data/models/project/project-media-file.model';

export class MapBoarMarker {
  width: number;
  height: number;
  x_pos: number;
  y_pos: number;

  constructor() {
    this.width = 25;
    this.height = 25;
  }
}

export class MapPinMarker {
  id: number;
  status: string;
  x_pos: number;
  y_pos: number;
  lotNumber: number;
  uniqueId: number;
  blockId: number;
  pinTitle: string;
  others: any;
}

@Component({
  selector: 'app-map-board',
  templateUrl: './map-board.component.html',
  styleUrls: ['./map-board.component.scss']
})
export class MapBoardComponent extends ComponentBase implements AfterViewInit, OnChanges {

  mapImage: HTMLImageElement = new Image();
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  markers: MapBoarMarker[] = [];
  @Input()
  pins: MapPinMarker[] = [];
  @Input()
  phaseIndex = -1;
  @Input()
  imageSrc: string;
  @Input()
  uniqueId = -1;
  @Input()
  pinLimit = 0;
  @Input()
  enableRemoveButton = false;
  @Output()
  imageWasRemoved: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  galleryImages: { src: string, typeId: number }[] = [];

  @Output()
  mapClick: EventEmitter<{ source: MapPinMarker, phaseIndex: number, unitIndex: number, type: string }>
    = new EventEmitter<{ source: MapPinMarker, phaseIndex: number, unitIndex: number, type: string }>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onAfterReserve: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  viewingType = 'create';

  isAddFormDisabled = false;

  pinSource: MapPinMarker[] = [];

  pinImageSrc: string;

  IS_FIRST_LOAD = true;

  /**
   * Itemsource for adding
   */
  @Input()
  blockSource: {
    id: number,
    pinLimit: number,
    title: string,
    data?: any,
    other: any
  } = {
      id: -1,
      pinLimit: 0,
      title: '',
      other: null
    };
  /**
   * Itemsource for viewing
   */
  @Input()
  mapUnitSource: any[] = [];

  CANVAS_DIMESIONS_STYLES = {
    height: '0px',
    width: '0px'
  };

  editState: MapPinMarker = null;
  removedUnitIdsEdit: any = [];
  constructor(
    private local: LocalStorageService,
    private utilities: UtilitiesService,
    private commonAjaxService: CommonAjaxService,
    private sanitizer: DomSanitizer,
    private changeRef: ChangeDetectorRef
  ) {
    super();
  }


  ngOnInit() {
    this.mapImage.src = this.imageSrc;
    this.pinSource = this.pins;
    if (this.viewingType === 'edit') {
      for (const x of this.pins) {
        if (x.y_pos === null || x.x_pos === null) {
          this.removedUnitIdsEdit.push(x);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isAddFormDisabled = this.blockSource == null || typeof this.blockSource === 'undefined';
    if (this.viewingType === 'reservation' || this.viewingType === 'view') {
      if (!this.IS_FIRST_LOAD) {
        this.IS_BUSY = true;
        setTimeout(() => {
          this.processChange().then((res) => {
            this.processPins(res);
            this.IS_FIRST_LOAD = false;
            this.IS_BUSY = false;
          });
        }, 1000);
      }
    }

  }


  removeImage() {
    this.imageSrc = null;
    this.imageWasRemoved.emit(true);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isAddFormDisabled = this.blockSource == null || typeof this.blockSource === 'undefined';
      this.draw();
    }, 200);
  }

  async processChange() {
    if (this.viewingType === 'reservation' || this.viewingType === 'view') {
      return new Promise((resolve) => {
        resolve(this.mapUnitSource);
      });
    } else {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
  }

  onMouseDown(event: any) {
    if (this.viewingType === 'create' || this.viewingType === 'edit') {
      if (this.uniqueId === -1) {
        this.utilities.ShowMaterialMessageBox({
          title: 'Notice',
          content: 'Please select what type of unit'
        }, 'warning').afterClosed().subscribe(() => {
          return;
        });
      } else {
        const pinFiltered = this.pinSource.filter((data) => {
          return data.uniqueId === this.uniqueId && data.blockId === this.blockSource.id
            && data.x_pos !== null && data.y_pos !== null &&
            (this.blockSource.data != null ? data.others.data.sqm
              === this.blockSource.data.sqm : data.others.data.sqm === this.blockSource.other.data.sqm);
        });
        if (+this.blockSource.pinLimit <= pinFiltered.length) {
          this.utilities.ShowMaterialMessageBox({
            title: 'Warning',
            content: 'Maximum number of pins reached'
          }, 'warning').afterClosed().subscribe(() => {
            return;
          });
        } else {

          // Move the marker when placed to a better location
          const marker = new MapBoarMarker();
          marker.x_pos = event.offsetX - (marker.width / 4);
          marker.y_pos = event.offsetY - (marker.height / 3);
          const sqm = this.blockSource.data != null ? this.blockSource.data.sqm : this.blockSource.other.data.sqm;
          const results: MapPinMarker = {
            id: -1,
            x_pos: marker.x_pos,
            y_pos: marker.y_pos,
            lotNumber: null,
            status: 'on-set',
            uniqueId: this.uniqueId,
            pinTitle: `${this.blockSource.title} (${sqm} sqm)`,
            others: this.blockSource.other,
            blockId: this.blockSource.id
          };
          if (this.viewingType === 'create') {
            this.pins.push(results);
          } else if (this.viewingType === 'edit') {
            const randomSourceIndex = this.removedUnitIdsEdit.findIndex((r: any) => {
              return r.blockId === this.blockSource.id
                && r.others.data.id === this.blockSource.other.data.id;
            });
            if (randomSourceIndex !== -1) {
              const randomSource = this.removedUnitIdsEdit.splice(randomSourceIndex, 1);
              if (randomSource && randomSource.length >= 1) {
                const randomPin = randomSource[0];
                const pin = this.pins.findIndex((item) => {
                  return item.blockId === randomPin.blockId
                    && item.uniqueId === randomPin.uniqueId
                    && item.others.data.id === randomPin.others.data.id
                    && item.x_pos === null
                    && item.y_pos === null;
                });
                if (pin !== -1) {
                  results.id = randomPin.id;
                  this.pins[pin].lotNumber = null;
                  this.pins[pin].x_pos = results.x_pos;
                  this.pins[pin].y_pos = results.y_pos;
                }
              }
            } else {
              this.pins.push(results);
            }
          }
        }
      }
    } else {

    }

  }

  /**
   * This one is to make the image generate its consitent resolution
   */
  async draw() {
    if (this.utilities.validURL(this.imageSrc) && this.imageSrc !== null) {
      this.IS_BUSY = true;
      this.commonAjaxService.request('get', this.imageSrc, { responseType: 'blob' })
        .subscribe((res: any) => {
          const reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onload = (e: any) => {
            const imageData = new Image();
            imageData.src = e.target.result;
            this.pinImageSrc = (this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result) as any);
            setTimeout(() => {
              this.CANVAS_DIMESIONS_STYLES.height = `${imageData.height}px`;
              this.CANVAS_DIMESIONS_STYLES.width = `${imageData.width}px`;
              if (this.viewingType === 'reservation' || this.viewingType === 'view') {
                setTimeout(() => {
                  this.processChange().then((source) => {
                    this.processPins(source);
                    this.IS_FIRST_LOAD = false;
                    this.IS_BUSY = false;
                  });
                }, 200);
              } else {
                this.IS_BUSY = false;
              }
            }, 50);
          };
        }, (err) => {
          this.IS_BUSY = false;
        });
    } else {
      if (this.imageSrc !== null) {
        this.pinImageSrc = this.imageSrc;
        const request = new XMLHttpRequest();
        request.open('GET', this.imageSrc, true);
        request.responseType = 'blob';
        request.onload = () => {
          const reader = new FileReader();
          reader.readAsDataURL(request.response);
          reader.onload = (e: any) => {
            const imageData = new Image();
            imageData.src = e.target.result;
            setTimeout(() => {
              this.CANVAS_DIMESIONS_STYLES.height = `${imageData.height}px`;
              this.CANVAS_DIMESIONS_STYLES.width = `${imageData.width}px`;
            }, 50);
          };
        };
        request.send();
      }
    }
  }

  onMapSubmit(data: any, index: number) {
    this.pins[index] = data;
    this.mapClick.emit({ source: this.pins[index], phaseIndex: this.phaseIndex, unitIndex: index, type: 'update' });
  }

  onUnMapData(data: any, index: any) {
    if (this.viewingType === 'create') {
      this.mapClick.emit({ source: this.pins[index], phaseIndex: this.phaseIndex, unitIndex: index, type: 'removed' });
      this.pins.splice(index, 1);
    } else if (this.viewingType === 'edit') {
      this.pins[index].x_pos = null;
      this.pins[index].y_pos = null;
      this.removedUnitIdsEdit.push(_.cloneDeep(this.pins[index]));
    }
  }

  processPins(source: any) {
    this.pins = [];
    for (const item of source) {
      this.pins.push({
        id: item.id,
        status: item.status,
        x_pos: item.mapPin.x,
        y_pos: item.mapPin.y,
        lotNumber: item.lotNumber,
        uniqueId: item.typeId,
        blockId: item.block.id,
        pinTitle: `${item.type.alias} (${item.type.name})`,
        others: {
          block: item.block,
          unitType: item.type,
          unit: item,
          images: item.images
        }
      });
    }
  }

  ngOnDestroy(): void {

  }

}
