import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionLoggerService } from '@app/core/services/session-logger.service';

@Injectable({
  providedIn: 'root'
})
export class ClientGuard implements CanActivate {

  constructor(
    private sessionLogger: SessionLoggerService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (window.location.pathname === '/admin' && this.sessionLogger.getUserInfo().loginInfo.roleName !== 'Client') {
      return true;
    } else if (this.sessionLogger.getUserInfo().loginInfo.roleName === 'Client') {
      if (window.location.pathname === '/client') {
        return true;
      } else {
        this.router.navigate(['/client']);
        return true;
      }
    } else if (this.sessionLogger.getUserInfo().loginInfo.roleName === 'admin') {
      return true;
    } else {
      return true;
    }
  }
}
