import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  TemplateRef,
  ElementRef
} from '@angular/core';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { MapPinMarker } from '../map-board/map-board.component';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TempReservationService } from '@app/data/services/temp-reservation.service';
import { Subject } from 'rxjs';
import { TempReservation } from '@app/data/models/project/temp-reservation.model';
import { CommonAjaxService } from '@app/data/ajax/common-ajax.service';
import { environment } from 'environments/environment';
import { TmpReservation } from '@app/data/models/tmpReservaion.model';
import { ProjectMediaFile } from '@app/data/models/project/project-media-file.model';
import { Lightbox } from '@ngx-gallery/lightbox';
import { ImageSize, ThumbnailsPosition, Gallery, ImageItem } from '@ngx-gallery/core';
import { Unit } from '@app/data/models/project/unit.model';
import { UnitStatus } from '@app/data/models/project/unit-status.model';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { ComponentBase } from '@app/common/component-base';

@Component({
  selector: 'app-map-pin',
  templateUrl: './map-pin.component.html',
  styleUrls: ['./map-pin.component.scss']
})
export class MapPinComponent extends ComponentBase implements OnChanges, AfterViewInit {

  private $destroyed: Subject<any> = new Subject<any>();

  @Input()
  source: MapPinMarker;

  @Input()
  title: string;

  @Input()
  pinViewingType = 'create';

  @Input()
  disableAddForm = false;

  @Input()
  galleryImages: { src: string, typeId: number }[] = [];

  @Output()
  clickPin: EventEmitter<MapPinMarker> = new EventEmitter<MapPinMarker>();

  @Output()
  isUnMap: EventEmitter<MapPinMarker> = new EventEmitter<MapPinMarker>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onAfterReserve: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('pop')
  pop: any;

  @ViewChild('reservationConfirmationModal')
  reservationConfirmationModal: any;

  @ViewChild('imageModal')
  imageModal: any;

  @ViewChild('lotNoInput') lotNoInput: ElementRef;

  lotNumber: string;
  STATUS_CLASS = 'available';

  lotForm: FormGroup;
  reserveForm: FormGroup;
  reservationSlotForm: FormGroup;

  pinTitle = '';
  trackingNo: string;

  unitGalleryPictures: ImageItem[] = [];

  PIN_STYLES = {
    'top': '0px',
    'left': '0px'
  };
  modalRef: BsModalRef;
  constructor(
    private local: LocalStorageService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private tmpReservationSvc: TempReservationService,
    private commonAjaxSvc: CommonAjaxService,
    private lightbox: Lightbox,
    private gallery: Gallery,
    private UTILITIES: UtilitiesService
  ) {
    super();
  }

  onHidden() {
    if ((this.pinViewingType === 'create' || this.pinViewingType === 'edit') && this.lotForm.invalid) {
      this.isUnMap.emit(this.source);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.PIN_STYLES['top'] = `${this.source.y_pos}px`;
    this.PIN_STYLES['left'] = `${this.source.x_pos}px`;
    this.STATUS_CLASS = this.source.status;
  }

  submitForm(data: any) {
    this.source.lotNumber = data.name;
    this.pop.hide();
    this.clickPin.emit(this.source);
  }

  unMap() {
    this.pop.hide();
    this.isUnMap.emit(this.source);
  }

  ngOnInit() {
    this.PIN_STYLES['top'] = `${this.source.y_pos}px`;
    this.PIN_STYLES['left'] = `${this.source.x_pos}px`;
    this.STATUS_CLASS = this.source.status;
    this.pinTitle = this.title;
    if (this.source.others && this.source.others.data) {
      this.pinTitle = `${this.title} (${this.source.others.data.sqm} sqm)`;
    }
    if (this.pinViewingType === 'reservation') {
      if (this.galleryImages != null) {
        for (const x of this.galleryImages) {
          if (this.source.others.unitType.id === x.typeId) {
            this.unitGalleryPictures.push(new ImageItem({
              src: x.src,
              thumb: x.src
            }));
          }
        }
      }
      this.pinTitle = this.source.pinTitle;
      this.pinTitle = '';
      this.reserveForm = this.formBuilder.group({
        'email': new FormControl('', [Validators.required, Validators.email])
      });
    } else {
      if (typeof this.source !== 'undefined' && typeof this.source.lotNumber !== 'undefined') {
        this.lotForm = this.formBuilder.group({
          'name': new FormControl(this.source.lotNumber, [Validators.required, Validators.minLength(1)])
        });
      } else {
        this.lotForm = this.formBuilder.group({
          'name': new FormControl('', [Validators.required, Validators.minLength(1)])
        });
      }
      setTimeout(() => {
        if (this.lotNoInput && this.lotNoInput.nativeElement) {
          this.lotNoInput.nativeElement.focus();
        }
      }, 150);
    }
    this.reservationSlotForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required, Validators.minLength(2)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(2)]),
      contactNo: new FormControl('', [Validators.required, Validators.minLength(10)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      address: new FormControl('', [Validators.required, Validators.minLength(5)])
    });
    setTimeout(() => {
      if (typeof this.lotForm !== 'undefined' && this.lotForm.invalid) {
        this.pop.show();
      }
    }, 100);
  }

  openReservationModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {});
    this.pop.hide();
  }

  submitReservationModal(clientForm: any, unitData: any) {
    if (this.reservationSlotForm.invalid) {
      this.UTILITIES.ShowMaterialMessageBox({
        title: 'Opps!',
        content: 'Your form is incomplete, please review again'
      }, 'warning', 'ok');
      return;
    }
    const clientData = clientForm;
    const tmpReservation = new TmpReservation();
    tmpReservation.contactNo = clientData.contactNo;
    tmpReservation.firstName = clientData.firstName;
    tmpReservation.lastName = clientData.lastName;
    tmpReservation.unitId = unitData.id;
    tmpReservation.email = clientData.email;
    tmpReservation.address = clientData.address;
    const enpdpoint = `${environment.apiUrl}/reservations/public-reserve/`;
    this.commonAjaxSvc.post(enpdpoint, tmpReservation).subscribe((resp: any) => {
      this.modalRef.hide();
      this.trackingNo = resp;
      this.STATUS_CLASS = 'TEMP_RESERVED';
      this.onAfterReserve.emit({ trackingNumber: resp });
      this.openReservationModal(this.reservationConfirmationModal);
      this.pop.hide();
    }, (err) => {
      this.UTILITIES.ShowMaterialMessageBox({
        title: 'Oh No!',
        content: 'We\'re unable to proceed your reservation, ' +
          '<br/> please contact us via Contact Us page so we could resolve it immediately.'
      }, 'danger', 'ok');
    });
  }

  openImageModal() {
    if (this.unitGalleryPictures === null || this.unitGalleryPictures.length <= 0) {
      return;
    }
    // Get a lightbox gallery ref
    const lightboxRef = this.gallery.ref('lightbox');

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Left
    });
    // Load items into the lightbox gallery ref
    lightboxRef.load(this.unitGalleryPictures);
    this.lightbox.open(0);
  }


  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + '-' + s4();
  }

  submitReserveForm() {
    this.pop.hide();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {

  }
}
