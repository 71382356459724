import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { SessionLoggerService } from '@app/core/services/session-logger.service';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss']
})
export class FooterBarComponent implements OnInit {
  activeLink = '';
  routeEventListener: Subscription;
  constructor(
    private router: Router,
    private sessionLogger: SessionLoggerService) {
    this.routeEventListener = router.events.subscribe(
    (event) => {
      if (event instanceof NavigationEnd) {
        this.activeLink = event.url;
      }
    });
  }

  ngOnInit() {
  }

}
