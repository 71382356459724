interface String {
    foo(): number;
}

interface Date {
    addDays(days: number): Date;
    minusDays(days: number): Date;
    addYears(years: number): Date;
    subtractYears(years: number): Date;
    toStringFormat(format: string): string;
}

Date.prototype.addDays = function (days: number) {
    const dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);

    return dat;
};

Date.prototype.minusDays = function (days: number) {
    const dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() - days);

    return dat;
};
Date.prototype.addYears = function (years: number) {
    const yr = new Date(this.valueOf());
    yr.setFullYear(yr.getFullYear() + years);

    return yr;
};

Date.prototype.subtractYears = function (years: number) {
    const yr = new Date(this.valueOf());
    yr.setFullYear(yr.getFullYear() - years);

    return yr;
};

Date.prototype.toStringFormat = function (format: string) {
    return '';
};
