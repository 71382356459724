import { Component, OnInit, Input, Output, Inject } from '@angular/core';
import { ComponentBase } from '@app/common/component-base';
import { MediaFile } from '@app/data/models/media-file.model';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { Lightbox } from '@ngx-gallery/lightbox';
import { ImageItem, Gallery, GalleryRef } from '@ngx-gallery/core';
import { MediaFileService } from '@app/data/services/media-file.service';
import { CommonAjaxService } from '@app/data/ajax/common-ajax.service';
import { SessionLoggerService } from '@app/core/services/session-logger.service';
import { ApplicationStorageService, ApplicationStorageKey } from '@app/shared/services/application-storage.service';
import { saveAs } from 'file-saver';
import { UtilitiesService } from '@app/shared/services/utilities.service';
@Component({
  selector: 'app-file-drawer-viewer',
  templateUrl: './file-drawer-viewer.component.html',
  styleUrls: ['./file-drawer-viewer.component.scss']
})
export class FileDrawerViewerComponent extends ComponentBase {

  @Input()
  itemsource: MediaFile[] = [];

  source: {
    selected: boolean,
    item: MediaFile
  }[] = [];
  lightBoxItems: ImageItem[] = [];
  mode: 'readonly' | 'update' = 'update';
  constructor(
    private bottomSheetRef: MatBottomSheetRef<FileDrawerViewerComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public lightbox: Lightbox,
    public gallery: Gallery,
    private MEDIA_FILE_SERVICE: MediaFileService,
    private commonAjaxService: CommonAjaxService,
    private applicationStorage: ApplicationStorageService,
    private sessionLogger: SessionLoggerService
  ) {
    super();
    this.itemsource = data.items;
    this.mode = data.mode;
    this.source = [];
    const lightboxRef = this.gallery.ref('lightbox');
    lightboxRef.reset();
    if (this.mode === 'readonly') {
      this.IS_BUSY = true;
      this.processFiles();
      this.IS_BUSY = false;
      this.populate(this.itemsource, lightboxRef);
    } else {
      this.populate(this.itemsource, lightboxRef);
    }


  }

  populate(itemsource: MediaFile[], lightboxRef: GalleryRef) {
    if (itemsource) {
      for (const x of itemsource) {
        this.source.push({
          selected: false,
          item: x
        });
        if (x.mimeType.includes('application/pdf') || x.mimeType.includes('text/')) {
          lightboxRef.addIframe({
            src: x.content || `${x['_links']['preview'].href}?access_token=${this.sessionLogger.access_token}`,
            thumb: '',
            title: x.fileName,
            type: x.mimeType
          });
        } else if (x.mimeType.includes('image')) {
          lightboxRef.addImage({
            src: x.content || `${x['_links']['preview'].href}?access_token=${this.sessionLogger.access_token}`,
            thumb: x.content || `${x['_links']['preview'].href}?access_token=${this.sessionLogger.access_token}`,
            title: x.fileName
          });
        }

      }
    }
  }
  processFiles() {
    if (this.itemsource) {
      const token = this.applicationStorage.GetItem(ApplicationStorageKey.token);
      if (token) {
        const source = JSON.parse(token);
        for (const x of this.itemsource) {
          x.content = `${x._links['preview'].href}?access_token=${source.access_token}`;
        }
      }
    }
  }

  ngOnInit() {
  }

  viewFile(index: number) {
    this.lightbox.open(index, 'lightbox');
  }

  canDelete() {
    let can = false;
    for (const x of this.source) {
      if (x.selected) {
        can = true;
        break;
      }
    }
    return can;
  }

  delete() {
    const toDeleteFiles = this.source
      .filter((item) => item.selected);
    this.bottomSheetRef.dismiss(toDeleteFiles);
  }

  download(doc: MediaFile) {
    this.commonAjaxService.getBlob(doc.content)
      .subscribe((res: any) => {
        saveAs(this.base64toBlob(res, doc.mimeType), doc.fileName);
      }, (err) => {
      });

  }

  base64toBlob(base64Data: any, contentType: any) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data.split(',')[1]);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  ngOnDestroy() {

  }

}
