import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { ProjectMediaFile } from '@app/data/models/project/project-media-file.model';
import { BaseService } from '../base-service';

@Injectable({
    providedIn: 'root'
})
export class ProjectMediaFilesService extends BaseService<ProjectMediaFile> {
    constructor(injector: Injector) {
        super(ProjectMediaFile, 'project-media-files', injector);
    }

}
