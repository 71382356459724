import { MapPin } from './map-pin.model';
import { UnitType } from './unit-type.model';
import { UnitStatus } from './unit-status.model';
import { BaseModel } from '../base.model';
import { Block } from '@app/data/models/project/block.model';

export class Unit extends BaseModel {
    /**
     * Integer value
     */
    lotNumber: number;

    /**
     * Float value
     */
    area: number;
    floorArea: number;

    /**
     * Decimal value
     */
    totalContractPrice: number;

    type: UnitType;
    typeId: number;
    mapPin: MapPin;
    status: UnitStatus;

    block: Block;

    blockId: number;
    phaseId: number;
    blockName?: string;

    getBlock() {
        return this.getRelation(Block, 'block');
    }

    getType() {
        return this.getRelation(UnitType, 'type');
    }
}
