import {
  Component,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { ComponentBase } from '@app/common/component-base';
import { SwTableSourceDirective } from '@app/shared/directives/sw-table-source.directive';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-sw-table-control',
  templateUrl: './sw-table-control.component.html',
  styleUrls: ['./sw-table-control.component.scss']
})
export class SwTableControlComponent extends ComponentBase {

  @Output()
  download: EventEmitter<any> = new EventEmitter();
  @Output()
  settings: EventEmitter<any> = new EventEmitter();
  @Output()
  applyFilter: EventEmitter<{ name: string, value: any, query: string }> = new EventEmitter<{ name: string, value: any, query: string }>();


  @Output()
  pageChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  pageSizeOptions: number[] = [5, 10, 20];
  @Input()
  page = 0;
  @Input()
  pageLength = 0;
  @Input()
  pageSize = this.pageSizeOptions[0];


  @Input()
  searchQuery = '';
  @Input()
  filterList: { name: string, value: any }[] = [];
  @Input()
  selectedFilter: { name: string, value: any };

  @Input()
  noActionButtons = false;

  selectedFilterValue: string;
  constructor() {
    super();
  }

  ngOnInit() {
    if (this.selectedFilter) {
      if (!this.isStandardParams(this.selectedFilter.name)) {
        this.searchQuery = this.selectedFilter.value;
        this.selectedFilterValue = this.selectedFilter.name;
      }
    }
  }

  onSearch() {
    const filter = this.filterList.find((item) => item.value === this.selectedFilterValue);
    this.applyFilter.emit({
      value: filter.value,
      name: filter.name,
      query: this.searchQuery
    });
  }

  swPageChange(evt: any) {
    this.pageChanged.emit(evt);
  }

  swFilterChange() {
    const filter = this.filterList.find((item) => item.value === this.selectedFilter.value);
    this.applyFilter.emit({
      value: filter.value,
      name: filter.name,
      query: this.searchQuery
    });
  }

  ngOnDestroy() {

  }
}
