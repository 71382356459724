import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { DataHttpInterceptor } from './config/data-http-interceptor';
import { ProfileRepositoryService } from './services/profile-repository.service';
import { AmenityService } from './services/project/amenity.service';
import { BlockService } from './services/project/block.service';
import { MediaFileService } from './services/media-file.service';
import { PhaseService } from './services/project/phase.service';
import { ProjectService } from './services/project/project.service';
import { TempReservationService } from './services/temp-reservation.service';
import { UnitService } from './services/project/unit.service';
import { UnitTypeService } from './services/project/unit-type.service';
import { AngularHalModule } from 'angular4-hal';
import { ExternalConfigService } from './config/external-config.service';
import { AccountDetailTypeService } from '@app/data/services/accounting/account-detail-type.service';
import { AccountTypeService } from '@app/data/services/accounting/account-type.service';
import { ChartOfAccountService } from '@app/data/services/accounting/chart-of-account.service';
import { FiscalYearService } from '@app/data/services/accounting/fiscal-year.service';
import { ClientContractService } from '@app/data/services/contract/client-contract.service';
import { ContractorContractService } from '@app/data/services/contract/contractor-contract.service';
import { LineItemService } from '@app/data/services/contract/line-item.service';
import { BillService } from '@app/data/services/transaction/bill.service';
import { ExpenseEntryService } from '@app/data/services/transaction/expense-entry.service';
import { JournalEntryService } from '@app/data/services/transaction/journal-entry.service';
import { PaymentTermService } from '@app/data/services/transaction/payment-term.service';
import { SalesInvoicePaymentService } from '@app/data/services/transaction/sales-invoice-payment.service';
import { SalesInvoiceService } from '@app/data/services/transaction/sales-invoice.service';
import { TransactionSettingService } from '@app/data/services/transaction/transaction-setting.service';
import { AgentService } from '@app/data/services/agent.service';
import { ContractorService } from '@app/data/services/contractor.service';
import { ClientService } from '@app/data/services/client.service';
import { BrokerService } from '@app/data/services/broker.service';
import { AuthService } from './auth/auth.service';
import { SharedModule } from '@app/shared/shared.module';
import { CommonAjaxService } from './ajax/common-ajax.service';
import { ProjectMediaFilesService } from './services/project/project-media-files.service';
import { ReservationService } from './services/reservation.service';
import { UserService } from './services/user.service';
import { RoleService } from './services/role.service';
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    AngularHalModule,
    SharedModule
  ],
  declarations: [],
  providers: [
    CommonAjaxService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataHttpInterceptor,
      multi: true
    },
    {
      provide: 'ExternalConfigurationService',
      useClass: ExternalConfigService
    },
    ProjectMediaFilesService
  ]
})
export class DataModule { }
