import { Resource, ResourceHelper } from 'angular4-hal';
import { User } from './user.model';


export class BaseModel extends Resource {
    id: number;
    version: number;
    createdBy: User;
    updatedBy: User;

    constructor({ ...props } = {}) {
        super();
        // convert object to resource model, instead of type casting
        ResourceHelper.instantiateResource(this, props);
    }
}
