import { Routes } from '@angular/router';
import { HomePageComponent } from './main/pages/home-page/home-page.component';
import { LoginPageComponent } from './main/pages/login-page/login-page.component';
import { AppComponent } from './app.component';
import { AdminPageComponent } from './main/pages/admin-page/admin-page.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { AccountPageComponent } from './main/pages/account-page/account-page.component';
import { ClientGuard } from './guards/client.guard';
import { RoleGuard } from './guards/role.guard';
export const AppRoutes: Routes = [
    {
        path: '', component: HomePageComponent,
        loadChildren: './modules/home/home.module#HomeModule'
    },
    {
        path: 'admin', component: AdminPageComponent,
        canActivate: [AuthGuard],
        data: {
            prohibitedRoles: ['investor'],
            allowedRoles: '*'
        },
        children: [
            {
                path: '',
                loadChildren: './modules/project-dashboard/project-dashboard.module#ProjectDashboardModule',
                data: {
                    module: 'project-dashboard.view',
                    access: [],
                    title: 'Projects'
                }
            },
            {
                path: 'projects',
                loadChildren: './modules/project-board/project-board.module#ProjectBoardModule',
                canLoad: [RoleGuard],
                data: {
                    module: 'project-board.view',
                    access: [],
                    title: 'Projects'
                }
            },
            {
                path: 'reservation',
                loadChildren: './modules/reservation/reservation.module#ReservationModule',
                canLoad: [RoleGuard],
                data: {
                    module: 'reservation.view',
                    access: [],
                    title: 'Reservation'
                }
            },
            {
                path: 'contract',
                loadChildren: './modules/contract-management/contract-management.module#ContractManagementModule',
                canLoad: [RoleGuard],
                data: {
                    module: 'contract-management.view',
                    access: [],
                    title: 'Contract Management'
                }
            },
            {
                path: 'accounting',
                loadChildren: './modules/accounting/accounting.module#AccountingModule',
                canLoad: [RoleGuard],
                data: {
                    module: 'chart-of-accounts.view, journal-entries.view, accounting-reports.view, accounting-settings.view',
                    access: [],
                    title: 'Accounting'
                }
            },
            {
                path: 'transaction',
                loadChildren: './modules/transaction/transaction.module#TransactionModule',
                canLoad: [RoleGuard],
                data: {
                    module: 'transaction.view',
                    access: [],
                    title: 'Transactions'
                }
            },
            {
                path: 'users',
                loadChildren: './modules/users/users.module#UsersModule',
                canLoad: [RoleGuard],
                data: {
                    module: 'users.view',
                    access: [],
                    title: 'Users'
                }
            },
            {
                path: 'client',
                loadChildren: './modules/client-transaction/client-transaction.module#ClientTransactionModule',
                canLoad: [RoleGuard],
                data: {
                    module: 'client',
                    access: [],
                    title: 'Client'
                }
            },
            {
                path: 'user-management',
                loadChildren: './modules/user-management/user-management.module#UserManagementModule',
                canLoad: [RoleGuard],
                data: {
                    module: 'user-management.account',
                    access: [],
                    title: 'User Management'
                }
            },
            {
                path: 'profile',
                loadChildren: './modules/profile/profile.module#ProfileModule',
            }
        ]
    },
    {
        path: 'client', component: AdminPageComponent, canActivate: [AuthGuard],
        data: {
            prohibitedRoles: '*',
            allowedRoles: ['client']
        },
        children: [
            {
                path: '',
                loadChildren: './modules/client-transaction/client-transaction.module#ClientTransactionModule',
                canActivate: [ClientGuard]
            }
        ]
    },
    {
        path: 'investor', component: AdminPageComponent, canActivate: [AuthGuard],
        data: {
            prohibitedRoles: '*',
            allowedRoles: ['investor']
        },
        children: [
            {
                path: '',
                loadChildren: './modules/project-dashboard/project-dashboard.module#ProjectDashboardModule',
                canActivate: [ClientGuard]
            }
        ]
    },
    {
        path: 'login', component: LoginPageComponent,
        loadChildren: './modules/login/login.module#LoginModule',
        canActivate: [LoginGuard]
    },
    {
        path: 'account', component: AccountPageComponent,
        loadChildren: './modules/account/account.module#AccountModule'
    },
    {
        path: '**', redirectTo: ''
    }
];
