import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MessageBoxConfig, MessageBoxType, MessageBoxButtons } from '@app/shared/services/utilities.service';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {

  config: {
    config: MessageBoxConfig,
    type: MessageBoxType,
    buttons: MessageBoxButtons
  };
  @ViewChild('footer')
  footer: ElementRef;
  content: string[] = [];

  @ViewChild('logo')
  logo: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<MessageBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      config: MessageBoxConfig,
      type: MessageBoxType,
      buttons: MessageBoxButtons
    }
  ) {
    this.config = data;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (typeof this.config.config.content === 'string') {
        this.content = this.config.config.content.split(/\n/);
      } else {
        this.content = this.config.config.content;
      }
      this.footer.nativeElement.classList.add(this.config.type);
      this.footer.nativeElement.classList.add(this.config.buttons);
      this.logo.nativeElement.classList.add(this.config.type);
    }, 15);
  }

  checkType(value: any) {
    return typeof value;
  }

  close() {
    this.dialogRef.close();
  }

  executeResult(result: string) {
    this.dialogRef.close(result);
  }
}
