import { Component, OnInit } from '@angular/core';
import { PageComponentBase } from '../../../common/page-component-base';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent  extends PageComponentBase {

  constructor() {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {

  }

}
