import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CommonAjaxBase } from '../common-ajax-base';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class CommonAjaxService extends CommonAjaxBase {

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  get(path: string, options?: CommonAjaxOptions) {
    return this.http.get(path, options).pipe(
      catchError(this.handleError)
    );
  }

  post(path: string, payload: any, options?: CommonAjaxOptions) {
    return this.http.post(path, payload, options).pipe(
      catchError(this.handleError)
    );
  }

  put(id: any, path: string, payload: any, options?: CommonAjaxOptions) {
    return this.http.put(`${path}/${id}`, payload, options).pipe(
      catchError(this.handleError)
    );
  }

  patch(id: any, path: string, payload: any, options?: CommonAjaxOptions) {
    return this.http.patch(`${path}/${id}`, payload, options).pipe(
      catchError(this.handleError)
    );
  }

  delete(id: any, path: string, options?: CommonAjaxOptions) {
    return this.http.delete(`${path}/${id}`, options).pipe(
      catchError(this.handleError)
    );
  }

  request(method: string, path: string, options: any) {
    return this.http.request(method, path, options).pipe(
      catchError(this.handleError)
    );
  }

  getBlob(path: string) {
    return this.http.request('get', path, {
      responseType: 'blob'
    }).pipe(
      mergeMap((source) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(source);
          reader.onload = (e: any) => {
            resolve(e.target.result);
          };
        });
      }),
      catchError(this.handleError)
    );
  }
}

export class CommonAjaxOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}
