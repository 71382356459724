import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '@app/common/component-base';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss']
})
export class AccountPageComponent extends ComponentBase {
  ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
  }
  constructor() {
    super();
   }

  ngOnInit() {
  }

}
