import { BaseModel } from '../base.model';
import { Project } from './project.model';

export class UnitType extends BaseModel {
    /**
     * Actual unit type name
     */
    name: string;
    /**
     * Alternate name for the unit type.
     * This will be used for branding purposes.
     */
    alias: string;
    project: Project;
}

