import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueChange'
})
export class ValueChangePipe implements PipeTransform {

  transform(value: any, change: any): any {
    if (value === null || value === '') {
      if (typeof change === 'undefined' || change == null) {
        return 'N/A';
      } else {
        return change;
      }
    }
    return value;
  }

}
