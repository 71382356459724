import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  OnDestroy,
  AfterViewInit,
  TemplateRef,
  ContentChild
} from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatTable } from '@angular/material';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject, from, merge } from 'rxjs';

export enum Actions {
  DOWNLOAD = 'download',
  EDIT = 'edit',
  DELETE = 'delete'
}
@Component({
  selector: 'app-sw-table',
  templateUrl: './sw-table.component.html',
  styleUrls: ['./sw-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwTableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  private $destroy: Subject<any> = new Subject<any>();
  @Input() columns: any;
  @Input() actions: Actions[];
  @Input() data: any[] = [];
  @Input() showFilter = true;
  @Input() customSort: (data: any, col: string) => any;
  @Input() customFilter: (data: any, filter: string) => boolean;
  @Input() formatData: (data: any, col: string) => any;
  @Input() showSpinner: boolean;
  @Input() pageState: any = {};
  @Input() isBackendPaginated: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @ContentChild(TemplateRef) avatarRef: TemplateRef<any>;

  @Output() actionClicked: EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChanged: EventEmitter<any> = new EventEmitter<any>();

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  displayedColumns: string[];
  filterColumns: string[];
  docs: any[];

  constructor(private cdf: ChangeDetectorRef) {
    this.formatData = (data: any, col: string) => data[col];
  }

  ngOnInit() {
    if (!this.isBackendPaginated) {
      this.dataSource.paginator = this.paginator;
    }
    this.dataSource.sort = this.sort;

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe((state: any) => {
        this.pageChanged.emit(state);
      });
  }

  ngOnChanges(change: any) {
    if (change['data']) {
      this.dataSource.data = this.data;
      if (this.isBackendPaginated && this.pageState && this.pageState.length) {
        this.paginator._pageIndex = this.pageState.pageIndex || 0;
        this.paginator.pageSize = this.pageState.pageSize || 5;
        this.paginator.length = this.pageState.length;
      }
    }
    if (change['columns']) {
      this.displayedColumns = Object.keys(this.columns);
      this.filterColumns = this.displayedColumns.filter(col => col !== 'actions');
    }
    if (change['customSort']) {
      this.dataSource.sortingDataAccessor = this.customSort;
    }

    if (change['customFilter']) {
      this.dataSource.filterPredicate = this.customFilter;
    }
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

  ngAfterViewInit() {

  }

  download(data: any) {
    this.docs = data.documents;
    this.actionClicked.emit({ type: Actions.DOWNLOAD, data: data });
  }

  edit(data: any) {
    this.actionClicked.emit({ type: Actions.EDIT, data: data });
  }

  delete(data: any) {
    this.actionClicked.emit({ type: Actions.DELETE, data: data });
  }

  filter(keyword: string) {
    this.dataSource.filter = keyword.trim().toLowerCase();
  }

  rowClick(row: any) {
    this.onRowClick.emit(row);
  }

  paginate(event: any) {
    this.pageChanged.emit(event);
  }
}



