import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest
} from '../../../../../node_modules/@angular/common/http';
import { ExternalConfigService } from '../config/external-config.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { User } from '../models/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { CommonAjaxBase } from '../common-ajax-base';
import { catchError } from 'rxjs/operators';


@Injectable()
export class AuthService extends CommonAjaxBase {

  private authToken: BehaviorSubject<AuthToken> = new BehaviorSubject<AuthToken>(null);
  authenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  returnUrl: string;
  constructor(
    private http: HttpClient
  ) {
    super();
  }

  get accessToken(): string {
    return this.authToken.getValue()
      ? this.authToken.getValue().access_token
      : null;
  }

  get basicAuth(): string {
    return btoa(`${this.API_TOKEN_USERNAME}:${this.API_TOKEN_PASS}`);
  }

  login(username: string, password: string) {
    const url: any = `${environment.apiUrl}/oauth/token`;
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', password);

    return this.http
      .post<AuthToken>(url, body, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
          'Authorization': `Basic ${this.basicAuth}`
        }
      })
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserInfo(username: string) {
    const url: any = `${environment.apiUrl}/users/me`;
    return this.http
      .get<User>(url)
      .pipe(
        catchError(this.handleError)
      );
  }
}


export class AuthToken {
  access_token: string;
  refresh_token: string;
  expires_in: string;
  scope: string;
  token_type: string;
  jti: string;
}
