import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recentTransactionType'
})
export class RecentTransactionTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === 'client_contract') {
      return 'Client Contract';
    } else if (value === 'contractor_contract') {
      return 'Contractor Contract';
    } else if (value === 'billing') {
      return 'Billing';
    } else {
      return 'Sales Invoice';
    }
  }

}
