import { Injectable } from '@angular/core';
import { decompress, compress } from 'lz-string';
@Injectable()
export class LocalStorageService implements Storage {
  constructor() {

  }

  [key: string]: any;
  length: number = localStorage.length;

  getItem(key: string): any {
    const item = decompress(localStorage.getItem(key));
    try {
      const data = item;
      return data;
    } catch {
      return null;
    }
  }

  setItem(key: string, value: string): void {
    const item = compress(value);
    localStorage.setItem(key, item);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  key(index: number): string {
    return localStorage.key(index);
  }

  clear(): void {
    localStorage.clear();
  }
}
