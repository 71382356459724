import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionLoggerService } from '@app/core/services/session-logger.service';
import { PermissionService } from '@app/shared/service/permission.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanLoad {

  constructor(
    private sessionLogger: SessionLoggerService,
    private router: Router,
    private permissionSvc: PermissionService,
  ) {

  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const data = route.data;
    const currentUser = this.sessionLogger.getUserInfo();
    if (currentUser == null) {
      this.router.navigate(['/']);
      return false;
    }
    if (data.module == null) {
      this.router.navigate(['/']);
      return false;
    }

    if (data.module) {
      const hasAccess = this.permissionSvc.hasAccess(data.module);
      if (!hasAccess) {
        this.router.navigate(['/']);
      }
      return hasAccess;
    }

    return false;
  }
}
