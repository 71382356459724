import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { ComponentBase } from 'app/common/component-base';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { PubSubService } from '@app/core/services/pub-sub.service';
import { App } from 'app/app.enum';
import { CacheHelper } from 'angular4-hal';
@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminPageComponent extends ComponentBase {
  STYLES = {
    'min-height': '0px'
  };
  activeLink = '';
  routeEventListener: Subscription;
  pubSubListener: Subscription;
  showOverlay = false;
  overlayListener: Subscription;
  title: string;
  constructor(
    private router: Router,
    private pubSub: PubSubService
  ) {
    super();
    CacheHelper.isActive = false;
    CacheHelper.initClearCacheProcess();
    this.STYLES['min-height'] = `${window.innerHeight}px`;
    this.routeEventListener = router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.includes('/client')) {
            this.activeLink = 'client';
          }
          if (event.url.includes('/admin') && !event.url.includes('profile')) {
            this.activeLink = '/';
          }
          if (event.url.includes('/admin/projects')) {
            this.activeLink = 'projects';
          }
          if (event.url.includes('/admin/users')) {
            this.activeLink = 'users';
          }
          if (event.url.includes('/admin/reservation')) {
            this.activeLink = 'reservation';
          }
          if (event.url.includes('/admin/contract')) {
            this.activeLink = 'contract';
          }

          if (event.url.includes('/admin/user-management')) {
            this.activeLink = 'user-management';
          }
          if (event.url.includes('/admin/accounting')) {
            this.activeLink = 'accounting';
          }
          if (event.url.includes('/admin/transaction')) {
            this.activeLink = 'transaction';
          }
        }
      });
    this.pubSubListener = this.pubSub.On<App>(App.OnAdminPageBusy)
      .subscribe((res) => {
        this.IS_BUSY = res;
      });
    this.overlayListener = this.pubSub.On<App>(App.OnShowDrawerOverlay)
      .subscribe(showOverlay => this.showOverlay = showOverlay);
  }

  ngOnInit() {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.STYLES['min-height'] = `${window.innerHeight}px`;
  }

  goToPage(path: string, activeLink: string) {
    if (window.innerWidth > 800 || this.activeLink !== activeLink) {
      this.router.navigate([path]);
    } else {
      document.getElementById('body').classList.toggle('admin-sidebar-show');
    }

  }

  closeOverlay() {
    document.getElementById('body').classList.remove('admin-sidebar-show');
  }

  ngOnDestroy(): void {
    if (this.routeEventListener != null) {
      this.routeEventListener.unsubscribe();
    }
    if (this.pubSubListener) {
      this.pubSubListener.unsubscribe();
    }
    if (this.overlayListener) {
      this.overlayListener.unsubscribe();
    }
  }

}
