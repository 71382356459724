import { Directive, AfterViewInit, OnDestroy, ElementRef, Host } from '@angular/core';
import { SlickComponent } from '../components/slick/slick.component';

@Directive({
  selector: '[appSlickItem]'
})
export class SlickItemDirective implements AfterViewInit, OnDestroy {
  constructor(public el: ElementRef, @Host() private carousel: SlickComponent) {
  }

  ngAfterViewInit() {
    this.carousel.addSlide(this);
  }

  ngOnDestroy() {
    this.carousel.removeSlide(this);
  }
}
