import { ExternalConfiguration, ExternalConfigurationHandlerInterface } from 'angular4-hal';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

/**
 * This services is needed by angular4-hal module
 */
@Injectable()
export class ExternalConfigService implements ExternalConfigurationHandlerInterface {

  constructor(
    private http: HttpClient
  ) { }

  deserialize(): any {
  }

  getExternalConfiguration(): ExternalConfiguration {
    return null;
  }

  getHttp(): any {
    return this.http;
  }

  getProxyUri(): string {
    return null;
  }

  getRootUri(): string {
    return environment.apiUrl;
  }

  serialize(): any {
  }

  setExternalConfiguration(externalConfiguration: ExternalConfiguration): any {
  }

}
