import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { MessageBoxButtons } from '@app/shared/services/utilities.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-message-box-bs',
  templateUrl: './message-box-bs.component.html',
  styleUrls: ['./message-box-bs.component.scss']
})
export class MessageBoxBsComponent implements OnInit, AfterViewInit {

  @ViewChild('footer')
  footer: ElementRef;

  @ViewChild('logo')
  logo: ElementRef;

  type = 'success';
  buttons: MessageBoxButtons = 'ok';
  content = '';
  title = '';
  domContent: any;
  constructor(public bsModalRef: BsModalRef,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.content = this.bsModalRef.content.content;
      this.domContent = this.sanitizer.bypassSecurityTrustHtml(this.content);
      this.title = this.bsModalRef.content.title;
      this.type = this.bsModalRef.content.type;
      this.buttons = this.bsModalRef.content.buttons;
      this.footer.nativeElement.classList.add(this.bsModalRef.content.type);
      this.footer.nativeElement.classList.add(this.bsModalRef.content.buttons);
      this.logo.nativeElement.classList.add(this.bsModalRef.content.type);
    }, 15);
  }

  close() {
    this.bsModalRef.hide();
  }

  yesNo(result: string) {
    this.bsModalRef.content.reason = result;
    this.bsModalRef.hide();
  }

}
