import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PubSubService } from '@app/core/services/pub-sub.service';
import { App } from './app.enum';
import { NavigationEnd, Router, NavigationStart } from '@angular/router';
import { setTheme } from 'ngx-bootstrap/utils';
import { ProjectService } from '@app/data/services/project/project.service';
import { MediaFileService } from '@app/data/services/media-file.service';
import { MediaFile } from '@app/data/models/media-file.model';
import { PageComponentBase } from './common/page-component-base';
import { CacheHelper } from 'angular4-hal';

declare const $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends PageComponentBase {

  title = 'starkwood-web';
  constructor(
    private pubsub: PubSubService,
    private router: Router,
    private projectSvc: ProjectService,
    private mediaFileSvc: MediaFileService,
    private cdRef: ChangeDetectorRef) {
    super();
    setTheme('bs4');
    pubsub.On<App>(App.OnBusy).subscribe((data: boolean) => {
      this.IS_BUSY = data;
      this.cdRef.detectChanges();
    });
  }


  async ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        if (window.location.pathname.includes('portfolio')) {
          document.body.classList.add('reservation');
        } else if (window.location.pathname.includes('admin')) {
          document.body.classList.remove('reservation');
        }
        const url = evt.url.split('?');
        if (url[0] === '/') {
          document.getElementById('body').classList.add('home');
          document.getElementById('body').classList.remove('admin');
          document.getElementById('body').classList.remove('login');
          document.getElementById('body').classList.remove('contact-us');
        } else if (url[0].includes('/admin')) {
          document.getElementById('body').classList.add('admin');
          document.getElementById('body').classList.remove('home');
          document.getElementById('body').classList.remove('login');
          document.getElementById('body').classList.remove('contact-us');
        } else if (url[0].includes('/contact-us')) {
          document.getElementById('body').classList.add('contact-us');
          document.getElementById('body').classList.remove('admin');
          document.getElementById('body').classList.remove('login');
          document.getElementById('body').classList.remove('home');
        } else if (url[0].includes('/login') || url[0].includes('/account')) {
          document.getElementById('body').classList.add('login');
          document.getElementById('body').classList.remove('home');
          document.getElementById('body').classList.remove('contact-us');
        } else {
          document.getElementById('body').classList.remove('login');
          document.getElementById('body').classList.remove('home');
          document.getElementById('body').classList.remove('admin');
          document.getElementById('body').classList.remove('contact-us');
        }
        setTimeout(() => {
          document.getElementById('app-root').classList.remove('hide');
          document.getElementById('body').classList.remove('pre-loader');
          $('#pre-loader').remove();
        }, 300);

        this.IS_BUSY = false;
      } else if (evt instanceof NavigationStart) {
        this.IS_BUSY = true;
      }
    });
  }

  ngOnDestroy(): void {

  }
}
