import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { SessionLoggerService } from '@app/core/services/session-logger.service';
import { PubSubService } from '@app/core/services/pub-sub.service';

/**
 * This module handles all the singleton services
 */
@NgModule({
  imports: [
      SharedModule
  ],
  exports: [],
  declarations: [],
  providers: [SessionLoggerService, PubSubService]
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
      if (parentModule) {
          throw new Error(
              'CoreModule is already loaded. Import it in the AppModule only');
      }
  }

  static forRoot(): ModuleWithProviders {
      return {
          ngModule: CoreModule,
          providers: [SessionLoggerService, PubSubService]
      };
  }
}
