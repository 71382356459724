import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { Unit } from '../../models/project/unit.model';
import { BaseService } from '../base-service';

@Injectable({
  providedIn: 'root'
})
export class UnitService extends BaseService<Unit> {
  constructor(injector: Injector) {
    super(Unit, 'units', injector);
  }
}
